import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import { connect } from 'react-redux'
import Pagination from '../../../components/pagination/pagination.component'
import './delivery-options.styles.scss'
import Service from './delivery-options.service'
import iconDelete from '../../../assets/icons/delete-red.svg'
import iconEdit from '../../../assets/icons/edit-dark.svg'
import DeleteDeliveryOption from './components/delete/delete.component'

class DeliveryOptions extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadDeliveryOptions()
    }

    onPageChange = page => {
        this.setState({
            ...this.state,
            currentPage: page
        }, () => this.service.loadDeliveryOptions())
    }

    onFilterChange = e => {
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                value: "",
                [e.target.name]: e.target.value
            }
        })
    }

    onFilterSubmit = e => {
        e.preventDefault()
        this.service.loadDeliveryOptions()
    }

    toggleShowDelete = deliveryOption => this.setState({
        ...this.state,
        showDelete: !this.state.showDelete,
        deliveryOptionToDelete: deliveryOption
    })

    onDelete = () => this.setState({
        ...this.state,
        showDelete: false,
        deliveryOptions: this.state.deliveryOptions.filter(deliveryOption => deliveryOption.id !== this.state.deliveryOptionToDelete.id),
        total: this.state.total - 1
    })

    render() {
        return (
            <>
                {this.state.loadingDeliveryOptions && <Dialog><Loading /></Dialog>}
                {this.state.showDelete && <Dialog><DeleteDeliveryOption deliveryOption={this.state.deliveryOptionToDelete} onDelete={this.onDelete} onCancel={() => this.toggleShowDelete(null)} /></Dialog>}
                <div className="delivery-options">
                    <div className="header">
                        <h4>DeliveryOptions</h4>
                        <div className="buttons">
                            <Link className="btn" to="/delivery/delivery-option/create">Create Delivery Option</Link>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Area</th>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Pricing</th>
                                <th>Created</th>
                                <th>Updated</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.deliveryOptions.map((deliveryOption, key) => <tr key={key}>
                                    <td>{deliveryOption.area.route.name} - {deliveryOption.area.name}</td>
                                    <td>{deliveryOption.name}</td>
                                    <td>{deliveryOption.description}</td>
                                    <td>{deliveryOption.pricingClass.name}</td>
                                    <td>{new Date(deliveryOption.created).toString("dd MMM yyyy HH:mm:ss")}</td>
                                    <td>{new Date(deliveryOption.updated).toString("dd MMM yyyy HH:mm:ss")}</td>
                                    <td>
                                        <div className="actions">
                                            <img alt="" src={iconEdit} onClick={() => this.props.history.push('/delivery/delivery-option/' + deliveryOption.id)} />
                                            <img alt="" src={iconDelete} onClick={() => this.toggleShowDelete(deliveryOption)} />
                                        </div>
                                    </td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                    {
                        this.state.total > 0 && <Pagination totalItemsCount={this.state.total} currentIndex={this.state.currentPage} itemsPerPage={this.state.pageSize} onChange={this.onPageChange} />
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(DeliveryOptions))