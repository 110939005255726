import React from 'react'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'

const Form = (props) => {
    return (
        <form method="post" onSubmit={props.onSubmit}>
            <div className="inputs">
                <div className="row" id="route">
                    <div className="form-input">
                        <label>Item</label>
                        <select name="deliverableItemId" onChange={props.onChange} value={props.inventory.deliverableItemId}>
                            <option value=""></option>
                            {
                                props.items.map((item, key) => <option key={key} value={item.id}>{item.name}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.deliverableItemId}</span>
                    </div>
                </div>
                <div className="row" id="quantity">
                    <div className="form-input">
                        <label>Quantity</label>
                        <input type="text" name="quantity" value={props.inventory.quantity} onChange={props.onChange} />
                        <span className="error">{props.errors.quantity}</span>
                    </div>
                </div>
                <div className="row" id="reference">
                    <div className="form-input">
                        <label>Reference</label>
                        <input type="text" name="reference" value={props.inventory.reference} onChange={props.onChange} />
                        <span className="error">{props.errors.reference}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="reset" onClick={() => props.history.push('/marketplace/inventory/all')}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Save</button>
            </div>
        </form>
    )
}

export default withRouter(Form)
