import React from 'react'
import { Switch, Route } from "react-router-dom"
import Create from './create/create.page'
import Inventories from './inventories.page'

const InventoryController = () => {
    return (
        <div className="inventory">
            <Switch>
                <Route path="/marketplace/inventory/create" component={Create} />
                <Route path="/marketplace/inventory/all" component={Inventories} />
            </Switch>
        </div>
    )
}

export default InventoryController