import Requests from '../../../../utils/Requests'
import { isValid } from '../route.common'

const getDefaultState = () => {
    return {
        loadingRoute: true,
        saving: false,
        route: {
            name: "",
            description: ""
        },
        showError: false,
        alertError: {},
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}
const loadRoute = instance => {

    let data = {
        routeId: instance.props.match.params.id
    }

    Requests(instance.props)
        .post()
        .withUrl("/admin/delivery/route")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleLoadRouteResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadRouteResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingRoute: false,
                route: response.data.route
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const saveRoute = instance => {

    Requests(instance.props)
        .post()
        .withUrl("/admin/delivery/route/update")
        .withAuthorization(instance.props.auth.authorization)
        .withData(instance.state.route)
        .then(response => {
            handleSaveRouteResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleSaveRouteResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "success",
                    text: "Saved successfully"
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadingRoute: false,
        saving: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Okay",
            onClick: () => instance.setState({
                ...instance.state,
                showError: false
            }),
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        isValid: () => isValid(instance),
        loadRoute: () => loadRoute(instance),
        saveRoute: () => saveRoute(instance)
    }
}

export default Service