import Requests from '../../../../utils/Requests'
import { isValid, loadDeliverables } from '../item.common'

const getDefaultState = () => {
    return {
        creating: false,
        loadingDeliverables: false,
        item: {
            deliverableId: "",
            name: "",
            category: "",
            description: "",
            amount: "",
            charges: "",
            weight: "",
            maxOrderQuantity: ""
        },
        deliverables: [],
        errors: {},
        showError: false,
        alertError: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const createItem = instance => {

    let data = {
        ...instance.state.item,
        amount: instance.state.item.amount * 100,
        charges: instance.state.item.charges * 100
    }

    Requests(instance.props)
        .post()
        .withUrl("/admin/vendor/deliverable-item/create")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleCreateItemResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleCreateItemResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                creating: false,
                item: {
                    deliverableId: "",
                    name: "",
                    category: "",
                    description: "",
                    amount: "",
                    charges: "",
                    weight: "",
                    maxOrderQuantity: ""
                },
                flag: {
                    type: "success",
                    text: "Created Successfully"
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        creating: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onCreateItem,
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        loadDeliverables: () => loadDeliverables(instance),
        createItem: () => createItem(instance)
    }
}

export default Service
