const columns = [
    {
        name: "0",
        label: "All",
        type: "S"
    },
    {
        name: "1",
        label: "Customer",
        type: "S"
    }
]

export default columns