import React from 'react'
import { Switch, Route } from "react-router-dom"
import Transactions from './transactions.page'
import CreateTransaction from './create/create.page'

const TransactionController = () => {
    return (
        <div className="transaction">
            <Switch>
                <Route path="/wallet/transaction/create" component={CreateTransaction} />
                <Route path="/wallet/transaction/all" component={Transactions} />
            </Switch>
        </div>
    )
}

export default TransactionController