import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import StatusLabels from '../../data/status-label'
import Logo from '../../components/brands/logo/logo.component'
import Service from './electricity.service'

class Electricity extends React.Component {
    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    getLogo = distributor => {
        switch (distributor) {
            case 0:
                return <Logo name="bedc" />
            case 1:
                return <Logo name="ikedc" />
            case 2:
                return <Logo name="ekedc" />
            case 3:
                return <Logo name="phed" />
            case 4:
                return <Logo name="aedc" />
            case 5:
                return <Logo name="kedco" />
            case 6:
                return <Logo name="jed" />
            case 7:
                return <Logo name="ibedc" />
            case 8:
                return <Logo name="kaedco" />
            default:
                return <Logo name="bedc" />
        }
    }

    onDownloadReceipt = e => {
        e.preventDefault()
        this.setState({
            ...this.state,
            loadingReceipt: true
        }, this.service.loadReceipt)
    }

    render(){
        return (
            <>
            <div className="top">
            {this.getLogo(this.props.electricity.meter.distributor)}
            </div>
            <div className="summary">
                <div className="info">
                    <p>Date</p>
                    <p>{new Date(this.props.created).toString("dd MMM yyyy")}</p>
                </div>
                <div className="info">
                    <p>Transaction ID</p>
                    <p>{this.props.number}</p>
                </div>
                <div className="info">
                    <p>Customer Name</p>
                    <p>{this.props.customer.firstname} {this.props.customer.lastname}</p>
                </div>
                <div className="info">
                    <p>Customer Phone</p>
                    <p>{this.props.customer.phone}</p>
                </div>
                <div className="info">
                    <p>Customer Email</p>
                    <p>{this.props.customer.email}</p>
                </div>
                <div className="info">
                    <p>Meter Number</p>
                    <p>{this.props.electricity.meter.number}</p>
                </div>
                <div className="info">
                    <p>Meter Name</p>
                    <p>{this.props.electricity.meter.name}</p>
                </div>
                <div className="info">
                    <p>Meter Type</p>
                    <p>{this.props.electricity.meter.type === 0? "Prepaid": "Postpaid"}</p>
                </div>
                <div className="info">
                    <p>Nickname</p>
                    <p>{this.props.electricity.meter.alias}</p>
                </div>
                {
                    this.props.electricity.token !== null &&
                    <div className="info">
                        <p>Token</p>
                        <p><strong>{this.props.electricity.token}</strong></p>
                    </div>
                }
                {
                    this.props.electricity.resetTokens !== null &&
                    <div className="info">
                        <p>Reset Tokens</p>
                        <p><strong>{this.props.electricity.resetTokens.replace(",", " ")}</strong></p>
                    </div>
                }
                <div className="info">
                    <p>Amount</p>
                    <NumberFormat value={this.props.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <p>{value}</p>} />
                </div>
                <div className="info">
                    <p>Status</p>
                    <p className="status">{StatusLabels[this.props.status]}</p>
                </div>
                {
                    this.props.status === 2 && this.props.electricity.meter.distributor === 0 && <button onClick={this.onDownloadReceipt} className="download">Download Receipt</button>
                }
            </div>
        </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Electricity))