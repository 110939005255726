import axios from 'axios'
import { API_ENDPOINT } from '../config'

const withUrl = (instance, url) => {
    instance.url = API_ENDPOINT + url
    return instance
}

const withData = (instance, data) => {
    instance.data = data
    return instance
}

const withAuthorization = (instance, authorization) => {
    instance.headers.authorization = authorization
    return instance
}

const withPost = instance => {
    instance.method = "post"
    return instance
}

const withGet = instance => {
    instance.method = "get"
    return instance
}

const then = (instance, response, error) => {
    instance.response = response
    instance.error = error
    axios({
        method: instance.method,
        url: instance.url,
        headers: instance.headers,
        data: instance.data
    })
        .then(_response => handleResponse(instance, _response))
        .catch(_error => instance.error(_error))
}

const handleResponse = (instance, response) => {
    if (typeof response.data.status === 'undefined') {
        instance.error("Invalid Response")
        return
    }

    switch (response.data.status.code) {
        case 17001:
            instance.props.history.push('/auth/login')
            break
        default:
            instance.response(response.data)
    }
}

const Requests = props => {
    let requests = {
        props: props,
        method: "get",
        headers: {},
        data: {},
        withUrl: url => withUrl(requests, url),
        withAuthorization: authorization => withAuthorization(requests, authorization),
        post: () => withPost(requests),
        get: () => withGet(requests),
        withData: data => withData(requests, data),
        then: (response, error) => then(requests, response, error)
    }

    return requests
}

export default Requests
