import React from 'react'
import { Switch, Route } from "react-router-dom"
import ItemController from './item/item.controller'
import DeliverableController from './deliverable/deliverable.controller'
import InventoryController from './inventory/inventory.controller'
import './common.styles.scss'

const MarketplaceController = () => {
    return (
        <div className="marketplace">
            <Switch>
                <Route path="/marketplace/inventory" component={InventoryController} />
                <Route path="/marketplace/deliverable" component={DeliverableController} />
                <Route path="/marketplace/item" component={ItemController} />
            </Switch>
        </div>
    )
}

export default MarketplaceController