import React from 'react'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'

const Form = (props) => {
    return (
        <form method="post" onSubmit={props.onSubmit}>
            <div className="inputs">
                <div className="row" id="route">
                    <div className="form-input">
                        <label>Vendor</label>
                        <select name="vendorId" onChange={props.onChange} value={props.deliverable.vendorId}>
                            <option value=""></option>
                            {
                                props.vendors.map((vendor, key) => <option key={key} value={vendor.id}>{vendor.name}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.vendorId}</span>
                    </div>
                </div>
                <div className="row" id="sku">
                    <div className="form-input">
                        <label>Sku</label>
                        <input type="text" name="sku" value={props.deliverable.sku} onChange={props.onChange} />
                        <span className="error">{props.errors.sku}</span>
                    </div>
                </div>
                <div className="row" id="name">
                    <div className="form-input">
                        <label>Name</label>
                        <input type="text" name="name" value={props.deliverable.name} onChange={props.onChange} />
                        <span className="error">{props.errors.name}</span>
                    </div>
                </div>
                <div className="row" id="description">
                    <div className="form-input">
                        <label>Description</label>
                        <input type="text" name="description" value={props.deliverable.description} onChange={props.onChange} />
                        <span className="error">{props.errors.description}</span>
                    </div>
                </div>
                <div className="row" id="type">
                    <div className="form-input">
                        <label>Type</label>
                        <select name="type" onChange={props.onChange} value={props.deliverable.type}>
                            <option value=""></option>
                            <option value="1">Service</option>
                            <option value="2">Stocked Product</option>
                        </select>
                        <span className="error">{props.errors.type}</span>
                    </div>
                </div>
                {
                    String(props.deliverable.type) === "1" &&
                    <div className="row" id="chargesInPercent">
                        <div className="form-input">
                            <label>Charges (%)</label>
                            <input type="text" name="chargesInPercent" value={props.deliverable.chargesInPercent} onChange={props.onChange} />
                            <span className="error">{props.errors.chargesInPercent}</span>
                        </div>
                    </div>
                }
            </div>
            <div className="actions">
                <button type="reset" onClick={() => props.history.push('/marketplace/deliverable/all')}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Save</button>
            </div>
        </form>
    )
}

export default withRouter(Form)
