import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import { connect } from 'react-redux'
import Pagination from '../../../components/pagination/pagination.component'
import './items.styles.scss'
import Service from './items.service'
import iconDelete from '../../../assets/icons/delete-red.svg'
import iconEdit from '../../../assets/icons/edit-dark.svg'
import DeleteItem from './components/delete/delete.component'
import NumberFormat from 'react-number-format'

class Items extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadItems()
    }

    onPageChange = page => {
        this.setState({
            ...this.state,
            currentPage: page
        }, () => this.service.loadItems())
    }

    onFilterChange = e => {
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                value: "",
                [e.target.name]: e.target.value
            }
        })
    }

    onFilterSubmit = e => {
        e.preventDefault()
        this.service.loadItems()
    }

    toggleShowDelete = item => this.setState({
        ...this.state,
        showDelete: !this.state.showDelete,
        itemToDelete: item
    })

    onDelete = () => this.setState({
        ...this.state,
        showDelete: false,
        items: this.state.items.filter(item => item.id !== this.state.itemToDelete.id)
    })

    render() {
        return (
            <>
                {this.state.loadingItems && <Dialog><Loading /></Dialog>}
                {this.state.showDelete && <Dialog><DeleteItem item={this.state.itemToDelete} onDelete={this.onDelete} onCancel={() => this.toggleShowDelete(null)} /></Dialog>}
                <div className="items">
                    <div className="header">
                        <h4>Items</h4>
                        <div className="buttons">
                            <Link className="btn" to="/marketplace/item/create">Create Item</Link>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Vendor</th>
                                <th>Name</th>
                                <th>Category</th>
                                <th>Amount</th>
                                <th>Charges</th>
                                <th>Weight</th>
                                <th>QA</th>
                                <th>MOQ</th>
                                <th>Updated</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.items.map((item, key) => <tr key={key}>
                                    <td>{item.deliverable.vendor.name}</td>
                                    <td>{item.name}</td>
                                    <td>{item.category}</td>
                                    <td><NumberFormat value={item.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} /></td>
                                    <td>{item.deliverable.type === 2? <NumberFormat value={item.charges / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} />: "-"}</td>
                                    <td>{item.weight}</td>
                                    <td>{item.deliverable.type === 2? item.quantityAvailable: "-"}</td>
                                    <td>{item.maxOrderQuantity}</td>
                                    <td>{new Date(item.updated).toString("dd MMM yyyy HH:mm:ss")}</td>
                                    <td>
                                        <div className="actions">
                                            <img alt="" src={iconEdit} onClick={() => this.props.history.push('/marketplace/item/' + item.id)} />
                                            <img alt="" src={iconDelete} onClick={() => this.toggleShowDelete(item)} />
                                        </div>
                                    </td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                    {
                        this.state.total > 0 && <Pagination totalItemsCount={this.state.total} currentIndex={this.state.currentPage} itemsPerPage={this.state.pageSize} onChange={this.onPageChange} />
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Items))