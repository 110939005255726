import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import iconArrowDown from '../../../../assets/icons/arrow-down-light.svg'
import AuthHelper from '../../../../utils/AuthHelper'

export const Item = (props) => {
    let paths = props.location.pathname.split('/')
    return (
        <li className={props.active ? 'item active' : 'item'} onClick={props.onClick}>
            <span>
                <img src={props.icon} alt="" />
                <b>{props.text}</b>
                {
                    props.subItems.length > 0 && <img src={iconArrowDown} alt="" />
                }
            </span>
            <ul>
                {
                    getFilteredMenuByAuth(props.auth, props.subItems).map(
                        (menu, key) => <li key={key} className={(paths.length > 2 && menu.url.includes(paths[1]+"/"+paths[2]))?'active':''}><Link onClick={() => props.onSubItemClick(menu.url)} to={menu.url}>{menu.text}</Link></li>
                    )
                }
            </ul>
        </li>
    )
}

const getFilteredMenuByAuth = (auth, subItems) => subItems.filter(subItem => {
    return AuthHelper(auth).hasPermission(subItem.permissions)
})

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Item))