import React from 'react'
import { withRouter } from 'react-router-dom'
import './details.styles.scss'
import Service from './details.service'
import { connect } from 'react-redux'
import Electricity from './electricity/electricity.component'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import AlertDialog from '../../../components/dialog/alert/alert.component'
import Internet from './internet/internet.component'
import Cabletv from './cabletv/cabletv.component'
import Airtime from './airtime/airtime.component'
import Marketplace from './marketplace/marketplace.component'
import Ticket from './ticket/ticket.component'

class Details extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadOrder()
    }

    onLoadOrders = () => {
        this.setState({
            ...this.state,
            loadingOrder: true,
            showError: false
        }, () => this.service.loadOrder())
    }


    onUnHoldOrder = () => {
        this.setState({
            ...this.state,
            unHoldingOrder: true,
            showError: false
        }, () => this.service.unHoldOrder())
    }


    sendNotification = () => {
        this.setState({
            ...this.state,
            sendingNotification: true,
            showError: false
        }, () => this.service.sendNotification())
    }

    getDetailsByType = order => {
        switch (order.type) {
            case 0:
                return <Internet {...order} />
            case 1:
                return <Cabletv {...order} />
            case 2:
                return <Electricity {...order} />
            case 3:
                return <Marketplace {...order} />
            case 4:
                return <Airtime {...order} />
            case 5:
                return <Ticket {...order} />
            default:
                return <></>
        }
    }

    render() {
        return (
            <>
                {(this.state.loadingOrder || this.state.unHoldingOrder || this.state.sendingNotification) && <Dialog><Loading /></Dialog>}
                {this.state.showError && <AlertDialog {...this.state.alertError} />}
                <div className="details">
                    {
                        this.state.order !== null &&
                        <>
                            {this.getDetailsByType(this.state.order)}
                            {
                                this.state.order.status === 3 &&
                                <div className="info">
                                    <p>Cancellation Reason</p>
                                    <p className="status">{this.state.order.cancellationReason}</p>
                                </div>
                            }
                            <div className='actions'>
                                <button onClick={this.sendNotification}>Send Notification</button>
                                {
                                    this.state.order.status === 4 && <button onClick={this.onUnHoldOrder}>Remove Restriction</button>
                                }
                            </div>
                        </>

                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Details))