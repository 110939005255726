import Requests from '../../../../utils/Requests'
import { isValid, loadAreas, loadPricings } from '../delivery-option.common'

const getDefaultState = () => {
    return {
        creating: false,
        loadingAreas: true,
        loadingPricings: true,
        deliveryOption: {
            areaId: "",
            pricingClassId: "",
            name: "",
            description: ""
        },
        areas: [],
        pricings: [],
        errors: {},
        showError: false,
        alertError: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const createDeliveryOption = instance => {

    Requests(instance.props)
        .post()
        .withUrl("/admin/delivery/delivery-option/create")
        .withAuthorization(instance.props.auth.authorization)
        .withData(instance.state.deliveryOption)
        .then(response => {
            handleCreateDeliveryOptionResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleCreateDeliveryOptionResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                creating: false,
                deliveryOption: {
                    areaId: "",
                    pricingClassId: "",
                    name: "",
                    description: ""
                },
                flag: {
                    type: "success",
                    text: "Created Successfully"
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        creating: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onCreateDeliveryOption,
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        loadAreas: () => loadAreas(instance),
        loadPricings: () => loadPricings(instance),
        createDeliveryOption: () => createDeliveryOption(instance)
    }
}

export default Service
