import Requests from '../../../../utils/Requests'
import { isValid, loadVendors } from '../deliverable.common'

const getDefaultState = () => {
    return {
        creating: false,
        loadingVendors: false,
        deliverable: {
            vendorId: "",
            name: "",
            description: "",
            sku: "",
            type: "",
            chargesInPercent: "",

        },
        vendors: [],
        errors: {},
        showError: false,
        alertError: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const createDeliverable = instance => {

    Requests(instance.props)
        .post()
        .withUrl("/admin/vendor/deliverable/create")
        .withAuthorization(instance.props.auth.authorization)
        .withData(instance.state.deliverable)
        .then(response => {
            handleCreateDeliverableResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleCreateDeliverableResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                creating: false,
                deliverable: {
                    vendorId: "",
                    name: "",
                    description: "",
                    sku: "",
                    type: "",
                    chargesInPercent: "",
                },
                flag: {
                    type: "success",
                    text: "Created Successfully"
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        creating: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onCreateDeliverable,
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        loadVendors: () => loadVendors(instance),
        createDeliverable: () => createDeliverable(instance)
    }
}

export default Service
