import React from "react"
import './upload-image.styles.scss'
import imageAvatar from '../../../../../assets/images/avatar.png'

const UploadImage = props => {
    return (
        <div className="upload-image">
            <img src={props.imageUrl !== null ? props.imageUrl : imageAvatar} alt="" />
            <div className='upload-btn-wrapper'>
                <span className="btn-upload">Upload Image</span>
                <input type="file" name="file" accept=".jpg,.png" onChange={props.onChange} />
            </div>
        </div>
    )
}

export default UploadImage