import { combineReducers } from 'redux'
import auth from './auth/reducer'
import menu from './menu/reducer'
import search from './search/reducer'

const Reducer = combineReducers({
    menu,
    auth,
    search
})

export default Reducer