import React from 'react'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'

const Form = (props) => {
    return (
        <form method="post" onSubmit={props.onSubmit}>
            <div className="inputs">
                <div className="row" id="area">
                    <div className="form-input">
                        <label>Area</label>
                        <select name="areaId" onChange={props.onChange} value={props.deliveryOption.areaId}>
                            <option value=""></option>
                            {
                                props.areas.map((area, key) => <option key={key} value={area.id}>{area.route.name} - {area.name}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.areaId}</span>
                    </div>
                </div>
                <div className="row" id="pricingClassId">
                    <div className="form-input">
                        <label>Pricing</label>
                        <select name="pricingClassId" onChange={props.onChange} value={props.deliveryOption.pricingClassId}>
                            <option value=""></option>
                            {
                                props.pricings.map((pricing, key) => <option key={key} value={pricing.id}>{pricing.name}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.pricingClassId}</span>
                    </div>
                </div>
                <div className="row" id="name">
                    <div className="form-input">
                        <label>Name</label>
                        <input type="text" name="name" value={props.deliveryOption.name} onChange={props.onChange} />
                        <span className="error">{props.errors.name}</span>
                    </div>
                </div>
                <div className="row" id="description">
                    <div className="form-input">
                        <label>Description</label>
                        <input type="text" name="description" value={props.deliveryOption.description} onChange={props.onChange} />
                        <span className="error">{props.errors.description}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="reset" onClick={() => props.history.push('/delivery/delivery-option/all')}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Save</button>
            </div>
        </form>
    )
}

export default withRouter(Form)
