import Requests from '../../../../utils/Requests'
import Validation from '../../../../data/validation/validation'
import { EmailValidationRule, NumberValidationRule, StringValidationRule } from '../../../../data/validation/rules'

const getDefaultState = () => {
    return {
        creating: false,
        customerEmail: "",
        amount: "",
        description: "",
        type: "",
        source: "",
        refId: "",
        errors: {},
        showError: false,
        alertError: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.source, (error) => errors.source = error, { min: { value: 1, error: "Invalid" }, max: { value: 1, error: "Invalid" } })
    validation.addValidationRule(EmailValidationRule, instance.state.customerEmail, (error) => errors.customerEmail = error, { allowNull: false })
    validation.addValidationRule(StringValidationRule, instance.state.type, (error) => errors.type = error, { min: { value: 1, error: "Invalid" }, max: { value: 1, error: "Invalid" } })
    validation.addValidationRule(NumberValidationRule, instance.state.amount, (error) => errors.amount = error, { min: { value: 1, error: "Too low" }, max: { value: 10000000, error: "Too big" } })
    validation.addValidationRule(StringValidationRule, instance.state.description, (error) => errors.description = error, { min: { value: 5, error: "Too short" }, max: { value: 100, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.refId, (error) => errors.refId = error, { min: { value: 0, error: "Too short" }, max: { value: 30, error: "Too long" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Error"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const createTransaction = instance => {

    let data = {
        customerEmail: instance.state.customerEmail.trim(),
        source: instance.state.source,
        amount: instance.state.amount * 100,
        type: instance.state.type,
        description: instance.state.description,
        refId: instance.state.refId
    }

    Requests(instance.props)
        .post()
        .withUrl("/admin/billing/wallet/transaction/create")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleCreateTransactionResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleCreateTransactionResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.props.history.push('/wallet/transaction/all')
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        creating: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onCreateTransaction,
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        createTransaction: () => createTransaction(instance)
    }
}

export default Service
