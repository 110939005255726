import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Dialog from '../../../../components/dialog/dialog.component'
import Loading from '../../../../components/loading/loading.component'
import { connect } from 'react-redux'
import Pagination from '../../../../components/pagination/pagination.component'
import './bouquets.styles.scss'
import Service from './bouquets.service'
import NumberFormat from 'react-number-format'
import iconDelete from '../../../../assets/icons/delete-red.svg'
import DeleteBouquet from './components/delete/delete.component'

class Bouquets extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadBouquets()
    }

    onPageChange = page => {
        this.setState({
            ...this.state,
            currentPage: page
        }, () => this.service.loadBouquets())
    }

    onFilterChange = e => {
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                value: "",
                [e.target.name]: e.target.value
            }
        })
    }

    onFilterSubmit = e => {
        e.preventDefault()
        this.service.loadBouquets()
    }

    toggleShowDelete = _bouquet => this.setState({
        ...this.state,
        showDelete: !this.state.showDelete,
        bouquetToDelete: _bouquet
    })

    onDelete = () => this.setState({
        ...this.state,
        showDelete: false,
        bouquets: this.state.bouquets.filter(_bouquet => _bouquet.id !== this.state.bouquetToDelete.id),
        total: this.state.total - 1
    })

    getTypeLabel = type => {
        switch(type){
            case 0:
                return "Dstv"
            case 1:
                return "Gotv"
            case 2:
                return "Startimes"
            default:
                return "Unknown"
        }
    }

    render() {
        return (
            <>
                {this.state.loadingBouquets && <Dialog><Loading /></Dialog>}
                {this.state.showDelete && <Dialog><DeleteBouquet bouquet={this.state.bouquetToDelete} onDelete={this.onDelete} onCancel={() => this.toggleShowDelete(null)} /></Dialog>}
                <div className="bouquets">
                    <div className="header">
                        <h4>Bouquets</h4>
                        <div className="buttons">
                            <Link className="btn" to="/services/cabletv/bouquet/create">Create Bouquet</Link>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Name</th>
                                <th>Amount</th>
                                <th>VTPassCode</th>
                                <th>BaxiCode</th>
                                <th>Created</th>
                                <th>Updated</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.bouquets.map((_bouquet, key) => <tr key={key}>
                                    <td>{this.getTypeLabel(_bouquet.type)}</td>
                                    <td>{_bouquet.name}</td>
                                    <td>{<NumberFormat value={_bouquet.amount} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} />}</td>
                                    <td>{_bouquet.vtpassCabletvBouquetCode}</td>
                                    <td>{_bouquet.baxiCabletvBouquetCode}</td>
                                    <td>{new Date(_bouquet.created).toString("dd MMM yyyy HH:mm:ss")}</td>
                                    <td>{new Date(_bouquet.updated).toString("dd MMM yyyy HH:mm:ss")}</td>
                                    <td>
                                        <div className="actions">
                                            <img alt="" src={iconDelete} onClick={() => this.toggleShowDelete(_bouquet)} />
                                        </div>
                                    </td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                    {
                        this.state.total > 0 && <Pagination totalItemsCount={this.state.total} currentIndex={this.state.currentPage} itemsPerPage={this.state.pageSize} onChange={this.onPageChange} />
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Bouquets))