import axios from 'axios'
import {API_ENDPOINT} from '../../../config'

const getDefaultState = () => {
    return {
        loadingItems: false,
        showDelete: false,
        items: [],
        filter: {
            column: "",
            value: ""
        },
        selectedIndex: -1,
        currentPage: 1,
        pageSize: 10,
        total: 0
    }
}

const loadItems = instance => {
    instance.setState({ ...instance.state, loadingItems: true })
    let data = {
        offset: instance.state.currentPage - 1,
        maxSize: instance.state.pageSize,
    }
    if (instance.state.filter.column.length > 0 && instance.state.filter.value.length > 0) {
        data.criteria = instance.state.filter.column
        data.value = instance.state.filter.value
    }
    axios({
        method: 'post',
        url: API_ENDPOINT + "/admin/vendor/deliverable-items",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: data
    }).then(response => handleLoadItemsResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loadingItems: false
            })
            alert(error)
        })
}

const handleLoadItemsResponse = (instance, response) => {
    switch (response.data.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingItems: false,
                items: response.data.data.deliverableItems,
                total: response.data.data.total
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            instance.setState({
                ...instance.state,
                loadingItems: false
            })
            alert(response.data.status.message)
    }
}

const Service = instance => {
    return {
        loadItems: () => loadItems(instance),
        getDefaultState: () => getDefaultState(instance)
    }
}

export default Service