import { createStore } from 'redux'
import Reducer from '../reducers/reducer'
import DefaultMenuStore from './defaults/menu'
import DefaultAuthStore from './defaults/auth'
import DefaultSearchStore from './defaults/search'

const store = createStore(Reducer, {
    "menu": DefaultMenuStore,
    "auth": DefaultAuthStore,
    "search": DefaultSearchStore
})

export default store