import React from 'react'
import { Switch, Route } from "react-router-dom"
import Routes from './routes.page'
import Create from './create/create.page'
import Edit from './edit/edit.page'

const RouteController = () => {
    return (
        <div className="route">
            <Switch>
                <Route path="/delivery/route/create" component={Create} />
                <Route path="/delivery/route/all" component={Routes} />
                <Route path="/delivery/route/:id" component={Edit} />
            </Switch>
        </div>
    )
}

export default RouteController