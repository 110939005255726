import React from 'react'
import NumberFormat from 'react-number-format'
import StatusLabels from '../../data/status-label'
import Logo from '../../components/brands/logo/logo.component'

const Airtime = props => {
    return (
        <div className="airtime">
            <div className="top">
                {getLogo(props.airtime.network)}
                <NumberFormat value={props.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <h4>{value}</h4>} />
            </div>
            <div className="summary">
                <div className="info">
                    <p>Date</p>
                    <p>{new Date(props.created).toString("dd MMM yyyy")}</p>
                </div>
                <div className="info">
                    <p>Transaction ID</p>
                    <p>{props.number}</p>
                </div>
                <div className="info">
                    <p>Customer Name</p>
                    <p>{props.customer.firstname} {props.customer.lastname}</p>
                </div>
                <div className="info">
                    <p>Customer Phone</p>
                    <p>{props.customer.phone}</p>
                </div>
                <div className="info">
                    <p>Customer Email</p>
                    <p>{props.customer.email}</p>
                </div>
                <div className="info">
                    <p>Transaction Type</p>
                    <p>{props.airtime.type === 0 ? "Airtime" : "Data Bundle"}</p>
                </div>
                {
                    props.airtime.type === 1 &&
                    <>
                        <div className="info">
                            <p>Data Bundle</p>
                            <p>{props.airtime.dataBundleDescription}</p>
                        </div>
                    </>
                }
                <div className="info">
                    <p>Amount</p>
                    <NumberFormat value={props.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <p>{value}</p>} />
                </div>
                <div className="info">
                    <p>Beneficiary Number</p>
                    <p>{props.airtime.number}</p>
                </div>
                {
                    props.airtime.name &&
                    <>
                        <div className="info">
                            <p>Beneficiary Name</p>
                            <p>{props.airtime.name}</p>
                        </div>
                    </>
                }
                <div className="info">
                    <p>Status</p>
                    <p className="status">{StatusLabels[props.status]}</p>
                </div>
            </div>
        </div>
    )
}

const getLogo = network => {
    switch (network) {
        case 1:
            return <Logo name="airtel" />
        case 2:
            return <Logo name="glo" />
        case 3:
            return <Logo name="9mobile" />
        default:
            return <Logo name="mtn" />
    }
}

export default Airtime