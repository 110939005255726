import React from 'react'
import { Switch, Route } from "react-router-dom"
import Payouts from './payouts.page'

const PayoutController = () => {
    return (
        <div className="payout">
            <Switch>
                <Route path="/vendor/payout/all" component={Payouts} />
            </Switch>
        </div>
    )
}

export default PayoutController