import Validation from '../../../data/validation/validation'
import { NumberValidationRule, StringValidationRule } from '../../../data/validation/rules'
import Requests from '../../../utils/Requests'

export const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.item.deliverableId, (error) => errors.deliverableId = error, { min: { value: 2, error: "Invalid" }, max: { value: 36, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.item.name, (error) => errors.name = error, { min: { value: 3, error: "Invalid" }, max: { value: 100, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.item.category, (error) => errors.category = error, { min: { value: 0, error: "Invalid" }, max: { value: 20, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.item.description, (error) => errors.description = error, { min: { value: 3, error: "Invalid" }, max: { value: 100, error: "Invalid" } })
    validation.addValidationRule(NumberValidationRule, instance.state.item.amount, (error) => errors.amount = error, { min: { value: 1, error: "Invalid" }, max: { value: 1000000, error: "Invalid" } })
    validation.addValidationRule(NumberValidationRule, instance.state.item.charges, (error) => errors.charges = error, { min: { value: 0, error: "Invalid" }, max: { value: 10000, error: "Invalid" } })
    validation.addValidationRule(NumberValidationRule, instance.state.item.weight, (error) => errors.weight = error, { min: { value: 0, error: "Invalid" }, max: { value: 1000, error: "Invalid" } })
    validation.addValidationRule(NumberValidationRule, instance.state.item.maxOrderQuantity, (error) => errors.maxOrderQuantity = error, { min: { value: 1, error: "Invalid" }, max: { value: 100, error: "Invalid" } })
    
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Error"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}


export const loadDeliverables = instance => {

    instance.setState({ ...instance.state, loadingItems: true })
    let data = {
        offSet: 1,
        maxSize: 1000
    }

    Requests(instance.props)
        .post()
        .withUrl("/admin/marketplace/deliverables")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleLoadDeliverableResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadDeliverableResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingDeliverables: false,
                deliverables: response.data.deliverables,
                total: response.data.size
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadDeliverables: false,
        creating: false,
        saving: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Okay",
            onClick: () => instance.setState({
                ...instance.state,
                showError: false
            }),
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}