import Requests from "../../../utils/Requests"

const getDefaultState = () => {
    return {
        loadingOrder: true,
        unHoldingOrder: false,
        sendingNotification: false,
        order: null,
        showError: false,
        alertError: {}
    }
}

const loadOrder = instance => {
    Requests(instance.props)
        .post()
        .withUrl("/admin/order")
        .withAuthorization(instance.props.auth.authorization)
        .withData({number: instance.props.match.params.id})
        .then(response => {
            handleLoadOrderResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadOrderResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingOrder: false,
                order: response.data.order
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const unHoldOrder = instance => {
    let data = {
        orderId: instance.state.order.id,
        risk: 1
    }
    Requests(instance.props)
        .post()
        .withUrl("/admin/order/unhold")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleUnHoldOrderResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleUnHoldOrderResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                unHoldingOrder: false,
                order: {
                    ...instance.state.order,
                    status: 0
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const sendNotification = instance => {
    let data = {
        orderId: instance.state.order.id
    }
    Requests(instance.props)
        .post()
        .withUrl("/admin/order/notification/send")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleSendNotificationResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleSendNotificationResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                sendingNotification: false
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadingOrder: false,
        unHoldOrder: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onLoadOrder,
            onClose: () => instance.props.history.push('/dashboard')
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadOrder: () => loadOrder(instance),
        unHoldOrder: () => unHoldOrder(instance),
        sendNotification: () => sendNotification(instance)
    }
}

export default Service