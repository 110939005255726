import React from 'react'
import './create.styles.scss'
import { withRouter } from 'react-router-dom'
import Form from './form'
import Service from './create.service'
import FormHelper from '../../../../utils/FormHelper'
import { connect } from 'react-redux'
import Dialog from '../../../../components/dialog/dialog.component'
import Loading from '../../../../components/loading/loading.component'
import '../../common.styles.scss'
import AlertDialog from '../../../../components/dialog/alert/alert.component'

class Create extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount(){
        this.onLoadItems()
    }

    onLoadItems = () => this.setState({
        ...this.state,
        loadingItems: true
    }, this.service.loadItems())

    onSubmit = e => {
        e.preventDefault()
        this.service.isValid() && this.onStockInOut()
    }

    onStockInOut = () => this.setState({
        ...this.state,
        creating: true,
        showError: false,
        flag: {
            type: "",
            text: ""
        }
    }, () => this.service.createStockInOut())

    render() {
        return (
            <>
                {(this.state.creating || this.state.loadingItems) && <Dialog><Loading></Loading></Dialog>}
                {this.state.showError && <AlertDialog {...this.state.alertError} />}
                <div className="create">
                    <div className="header">
                        <h4>Stock In/Out Entry</h4>
                    </div>
                    <div className="card">
                        <div className={"flag " + this.state.flag.type}>
                            {this.state.flag.text}
                        </div>
                        <Form {...this.state} onChange={FormHelper(this, "inventory").onChange} onSubmit={this.onSubmit} />
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Create))
