import React from 'react'
import { Switch, Route } from "react-router-dom"
import TransactionController from './transaction/transaction.controller'
import Wallets from './wallets.page'
import './common.styles.scss'

const WalletController = () => {
    return (
        <div className="wallet">
            <Switch>
                <Route path="/wallet/transaction" component={TransactionController} />
                <Route path="/wallet/all" component={Wallets} />
            </Switch>
        </div>
    )
}

export default WalletController