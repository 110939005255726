import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import Dialog from '../../../../components/dialog/dialog.component'
import AlertDialog from '../../../../components/dialog/alert/alert.component'
import Loading from '../../../../components/loading/loading.component'
import Service from './status-metrics.service'
import iconOrder from '../../../../assets/icons/order.svg'
import iconArrow from '../../../../assets/icons/arrow-right-light.svg'
import './status-metrics.styles.scss'
import NumberFormat from 'react-number-format'

export const SEARCH_REQUESTED = "search_requested"

class OrderStatusMetrics extends React.Component {
    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadOrderStatusMetrics()
    }

    render() {
        return (
            <div className="status-metrics">
                {this.state.loadingOrderStatusMetrics && <Dialog><Loading /></Dialog>}
                {this.state.showError && <AlertDialog {...this.state.alertError} />}

                <div className="cards">
                    <div className="_card red">
                        <div className="content">
                            <div>
                                <h3><NumberFormat value={this.state.orderStatusMetrics.pending} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => value} /></h3>
                                <p>Pending Orders</p>
                            </div>
                            <img alt="" src={iconOrder} />
                        </div>
                        <div className="footer">
                            <Link to="/order/all?filter=4&value=CREATED">
                                <i>See all</i>
                                <img alt="" src={iconArrow} />
                            </Link>
                        </div>
                    </div>
                    <div className="_card green">
                        <div className="content">
                            <div>
                                <h3><NumberFormat value={this.state.orderStatusMetrics.processing} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => value} /></h3>
                                <p>Processing Orders</p>
                            </div>
                            <img alt="" src={iconOrder} />
                        </div>
                        <div className="footer">
                            <Link to="/order/all?filter=4&value=PROCESSING">
                                <i>See all</i>
                                <img alt="" src={iconArrow} />
                            </Link>
                        </div>
                    </div>
                    <div className="_card blue">
                        <div className="content">
                            <div>
                                <h3><NumberFormat value={this.state.orderStatusMetrics.onHold} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => value} /></h3>
                                <p>Orders OnHold</p>
                            </div>
                            <img alt="" src={iconOrder} />
                        </div>
                        <div className="footer">
                            <Link to="/order/all?filter=4&value=ON_HOLD">
                                <i>See all</i>
                                <img alt="" src={iconArrow} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(OrderStatusMetrics))