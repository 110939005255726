import Requests from '../../../../utils/Requests'
import { isValid, loadVendors } from '../deliverable.common'

const getDefaultState = () => {
    return {
        loadingDeliverable: true,
        loadingVendors: true,
        saving: false,
        deliverable: {
            vendorId: "",
            name: "",
        },
        vendors: [],
        showError: false,
        alertError: {},
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}
const loadDeliverable = instance => {

    let data = {
        deliverableId: instance.props.match.params.id
    }

    Requests(instance.props)
        .post()
        .withUrl("/admin/vendor/deliverable")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleLoadDeliverableResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadDeliverableResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingDeliverable: false,
                deliverable: {
                    ...response.data.deliverable,
                    vendorId: response.data.deliverable.vendor.id,
                    lowestAmount: response.data.deliverable.lowestAmount/100
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const saveDeliverable = instance => {

    Requests(instance.props)
        .post()
        .withUrl("/admin/vendor/deliverable/update")
        .withAuthorization(instance.props.auth.authorization)
        .withData(instance.state.deliverable)
        .then(response => {
            handleSaveDeliverableResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleSaveDeliverableResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "success",
                    text: "Saved successfully"
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const uploadImage = (instance, data) => {
    Requests(instance.props)
        .post()
        .withUrl("/admin/vendor/deliverable/upload-image")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleUploadImageResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleUploadImageResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                uploading: false,
                deliverable: {
                    ...instance.state.deliverable,
                    imageUrl: response.data.imageUrl
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadingDeliverable: false,
        saving: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Okay",
            onClick: () => instance.setState({
                ...instance.state,
                showError: false
            }),
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        isValid: () => isValid(instance),
        loadVendors: () => loadVendors(instance),
        loadDeliverable: () => loadDeliverable(instance),
        saveDeliverable: () => saveDeliverable(instance),
        uploadImage: (data) => uploadImage(instance, data)
    }
}

export default Service