import React from "react"
import './fixed-days.styles.scss'

const FixedDays = props => {
    return (
        <div className="fixed-days">
            <span className={Number(props.days) === 1 ? "fixed-day active" : "fixed-day"} onClick={() => props.onChange(1)}>1 Day</span>
            <span className={Number(props.days) === 7 ? "fixed-day active" : "fixed-day"} onClick={() => props.onChange(7)}>7 Days</span>
            <span className={Number(props.days) === 30 ? "fixed-day active" : "fixed-day"} onClick={() => props.onChange(30)}>30 Days</span>
            <span className={Number(props.days) === 90 ? "fixed-day active" : "fixed-day"} onClick={() => props.onChange(90)}>90 Days</span>
        </div>
    )
}

export default FixedDays