import axios from 'axios'
import {API_ENDPOINT} from '../../../config'

const getDefaultState = () => {
    return {
        loadingProcessorSettings: false,
        updateProcessorSettings: false,
        processorSettings: []
    }
}

const loadProcessorSettings = instance => {
    instance.setState({ ...instance.state, loadingProcessorSettings: true })

    axios({
        method: 'post',
        url: API_ENDPOINT + "/admin/vendor/processor-settings",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {}
    }).then(response => handleLoadProcessorSettingsResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loadingProcessorSettings: false
            })
            alert(error)
        })
}

const handleLoadProcessorSettingsResponse = (instance, response) => {
    switch (response.data.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingProcessorSettings: false,
                processorSettings: response.data.data.processorSettings
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            instance.setState({
                ...instance.state,
                loadingProcessorSettings: false
            })
            alert(response.data.status.message)
    }
}

const updateProcessorSettings = (instance, id, value) => {
    instance.setState({ ...instance.state, updateProcessorSettings: true })

    axios({
        method: 'post',
        url: API_ENDPOINT + "/admin/vendor/processor-settings/update",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            id: id,
            value: value
        }
    }).then(response => handleUpdateProcessorSettingsResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                updateProcessorSettings: false
            })
            alert(error)
        })
}

const handleUpdateProcessorSettingsResponse = (instance, response) => {
    switch (response.data.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                updateProcessorSettings: false
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            instance.setState({
                ...instance.state,
                updateProcessorSettings: false
            })
            alert(response.data.status.message)
    }
}

const Service = instance => {
    return {
        loadProcessorSettings: () => loadProcessorSettings(instance),
        updateProcessorSettings: (id, value) => updateProcessorSettings(instance, id, value),
        getDefaultState: () => getDefaultState(instance)
    }
}

export default Service