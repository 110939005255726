import React from 'react'
import './dashboard.styles.scss'
import TypeMetrics from './charts/type-metrics/type-metrics.component'
import StatusMetrics from './charts/status-metrics/status-metrics.component'
import DailyReport from './charts/daily-report/daily-report.component'
const Dashboard = () => {
    return (
        <div className="dashboard">
            <div className="charts">
                <div className="row">
                    <div className="chart">
                        <StatusMetrics />
                        <div className='col'>
                            <div className='col-2'>
                                <TypeMetrics />
                            </div>
                            <div className='col-2'>
                                <DailyReport/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard