import React from 'react'
import { withRouter } from 'react-router-dom'

const Form = (props) => {
    return (
        <form method="post" onSubmit={props.onSubmit}>
            <div className="inputs">
                <div className="row" id="deliverableId">
                    <div className="form-input">
                        <label>Deliverable</label>
                        <select name="deliverableId" onChange={props.onChange} value={props.item.deliverableId}>
                            <option value=""></option>
                            {
                                props.deliverables.map((deliverable, key) => <option key={key} value={deliverable.id}>{deliverable.name}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.deliverableId}</span>
                    </div>
                </div>
                <div className="row" id="name">
                    <div className="form-input">
                        <label>Name</label>
                        <input type="text" name="name" value={props.item.name} onChange={props.onChange} />
                        <span className="error">{props.errors.name}</span>
                    </div>
                </div>
                <div className="row" id="category">
                    <div className="form-input">
                        <label>Category</label>
                        <input type="text" name="category" value={props.item.category} onChange={props.onChange} />
                        <span className="error">{props.errors.category}</span>
                    </div>
                </div>
                <div className="row" id="description">
                    <div className="form-input">
                        <label>Description</label>
                        <input type="text" name="description" value={props.item.description} onChange={props.onChange} />
                        <span className="error">{props.errors.description}</span>
                    </div>
                </div>
                <div className="row" id="amount">
                    <div className="form-input">
                        <label>Amount</label>
                        <input type="text" name="amount" value={props.item.amount} onChange={props.onChange} />
                        <span className="error">{props.errors.amount}</span>
                    </div>
                </div>
                <div className="row" id="charges">
                    <div className="form-input">
                        <label>Charges</label>
                        <input type="text" name="charges" value={props.item.charges} onChange={props.onChange} />
                        <span className="error">{props.errors.charges}</span>
                    </div>
                </div>
                <div className="row" id="weight">
                    <div className="form-input">
                        <label>Weight</label>
                        <input type="text" name="weight" value={props.item.weight} onChange={props.onChange} />
                        <span className="error">{props.errors.weight}</span>
                    </div>
                </div>
                <div className="row" id="maxOrderQuantity">
                    <div className="form-input">
                        <label>Max. Order Qty.</label>
                        <input type="text" name="maxOrderQuantity" value={props.item.maxOrderQuantity} onChange={props.onChange} />
                        <span className="error">{props.errors.maxOrderQuantity}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="reset" onClick={() => props.history.push('/marketplace/item/all')}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Save</button>
            </div>
        </form>
    )
}

export default withRouter(Form)
