import React from 'react'
import { Bar } from 'react-chartjs-2'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Dialog from '../../../../components/dialog/dialog.component'
import AlertDialog from '../../../../components/dialog/alert/alert.component'
import Loading from '../../../../components/loading/loading.component'
import Service from './type-metrics.service'
import FixedDays from '../fixed-days/fixed-days.component'
import './type-metrics.styles.scss'

class OrderTypeMetrics extends React.Component {
    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadOrderTypeMetrics()
    }

    getData = () => {
        const days = this.getDays()
        const dataMetrics = this.getDataMetrics(days)

        let data = {
            labels: days,
            datasets: []
        }

        if (dataMetrics.length === 0) {
            return data
        }

        data.datasets = [
            {
                data: dataMetrics[0],
                fill: false,
                backgroundColor: "#8B0707",
                borderColor: "#8B0707",
                label: 'Internet'
            },
            {
                data: dataMetrics[1],
                fill: false,
                backgroundColor: "#990099",
                borderColor: "#990099",
                label: 'Cabletv'
            },
            {
                data: dataMetrics[2],
                fill: false,
                backgroundColor: "#FF9900",
                borderColor: "#FF9900",
                label: 'BEDC'
            },
            {
                data: dataMetrics[3],
                fill: false,
                backgroundColor: "#DC3912",
                borderColor: "#DC3912",
                label: 'Shawarma'
            },
            {
                data: dataMetrics[4],
                fill: false,
                backgroundColor: "#3366CC",
                borderColor: "#3366CC",
                label: 'Airtime'
            }
        ]

        return data
    }

    getDataMetrics = days => {
        let data = []
        for (let t = 0; t < 5; t++) {
            let typeData = []
            for (let d = 0; d < days.length; d++) {
                let day = days[d]
                typeData.push(this.getValue(day, t))
            }
            data[t] = typeData
        }
        return data
    }

    getDays = () => {
        let days = []
        for (let i = 0; i < this.state.orderTypeMetrics.length; i++) {
            let day = new Date(this.state.orderTypeMetrics[i].date).toString("dd MM yyyy")
            if (!days.includes(day)) {
                days.push(day)
            }
        }
        return days
    }

    getValue = (day, type) => {
        for (let i = 0; i < this.state.orderTypeMetrics.length; i++) {
            if (type === this.state.orderTypeMetrics[i].type && day === new Date(this.state.orderTypeMetrics[i].date).toString("dd MM yyyy")) {
                return Math.ceil(this.state.orderTypeMetrics[i].value / 100)
            }
        }
        return 0
    }

    onDaysChanged = days => this.setState({
        ...this.state,
        loadingOrderTypeMetrics: true,
        days: days
    }, this.service.loadOrderTypeMetrics)

    render() {
        return (
            <div className="type-metrics">
                {this.state.loadingOrderTypeMetrics && <Dialog><Loading /></Dialog>}
                {this.state.showError && <AlertDialog {...this.state.alertError} />}

                <div className="chart">
                    <Bar data={this.getData()} />
                </div>

                <div className='controls'>
                    <h4>Last {this.state.days} {this.state.days > 1 ? "Days" : "Day"}</h4>
                    <FixedDays days={this.state.days} onChange={this.onDaysChanged} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(OrderTypeMetrics))