import Requests from '../../../../utils/Requests'
import { isValid, loadAreas, loadPricings } from '../delivery-option.common'

const getDefaultState = () => {
    return {
        saving: false,
        loadingAreas: true,
        loadingPricings: true,
        loadingDeliveryOption: true,
        deliveryOption: {
            areaId: "",
            pricingClassId: "",
            name: "",
            description: ""
        },
        areas: [],
        pricings: [],
        showError: false,
        alertError: {},
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}
const loadDeliveryOption = instance => {

    let data = {
        deliveryOptionId: instance.props.match.params.id
    }

    Requests(instance.props)
        .post()
        .withUrl("/admin/delivery/delivery-option")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleLoadDeliveryOptionResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadDeliveryOptionResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingDeliveryOption: false,
                deliveryOption: {
                    ...response.data.deliveryOption,
                    areaId: response.data.deliveryOption.area.id,
                    pricingClassId: response.data.deliveryOption.pricingClass.id,
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const saveDeliveryOption = instance => {

    Requests(instance.props)
        .post()
        .withUrl("/admin/delivery/delivery-option/update")
        .withAuthorization(instance.props.auth.authorization)
        .withData(instance.state.deliveryOption)
        .then(response => {
            handleSaveDeliveryOptionResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleSaveDeliveryOptionResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "success",
                    text: "Saved successfully"
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadingArea: false,
        saving: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Okay",
            onClick: () => instance.setState({
                ...instance.state,
                showError: false
            }),
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        isValid: () => isValid(instance),
        loadAreas: () => loadAreas(instance),
        loadPricings: () => loadPricings(instance),
        loadDeliveryOption: () => loadDeliveryOption(instance),
        saveDeliveryOption: () => saveDeliveryOption(instance)
    }
}

export default Service