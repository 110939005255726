import React from 'react'

const Form = (props) => {
    return (
        <form method="post" onSubmit={props.onSubmit}>
            <div className="inputs">
                <div className="row" id="type">
                    <div className="form-input">
                        <label>Type</label>
                        <select name="type" onChange={props.onChange} value={props.type}>
                            <option value=""></option>
                            <option value="0">Dstv</option>
                            <option value="1">Gotv</option>
                            <option value="2">Startimes</option>
                        </select>
                        <span className="error">{props.errors.type}</span>
                    </div>
                </div>
                <div className="row" id="baxiCabletvBouquetCode">
                    <div className="form-input">
                        <label>Baxi Bouquet</label>
                        <select name="baxiCabletvBouquetCode" onChange={props.onChange} value={props.baxiCabletvBouquetCode}>
                            <option value=""></option>
                            {
                                props.baxiBouquets.filter(bouquet => String(bouquet.type) === props.type).map((bouquet, key) => <option value={bouquet.code} key={key}>{bouquet.name} N{bouquet.amount}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.baxiCabletvBouquetCode}</span>
                    </div>
                </div>
                <div className="row" id="vtpassCabletvBouquetCode">
                    <div className="form-input">
                        <label>VTPass Bouquet</label>
                        <select name="vtpassCabletvBouquetCode" onChange={props.onChange} value={props.vtpassCabletvBouquetCode}>
                            <option value=""></option>
                            {
                                props.vtpassBouquets.filter(bouquet => String(bouquet.type) === props.type).map((bouquet, key) => <option value={bouquet.code} key={key}>{bouquet.name} N{bouquet.amount}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.vtpassCabletvBouquetCode}</span>
                    </div>
                </div>
                <div className="row" id="name">
                    <div className="form-input">
                        <label>Name</label>
                        <input type="text" name="name" value={props.name} onChange={props.onChange} />
                        <span className="error">{props.errors.name}</span>
                    </div>
                </div>
                <div className="row" id="amount">
                    <div className="form-input">
                        <label>Amount</label>
                        <input type="number" name="amount" value={props.amount} onChange={props.onChange} />
                        <span className="error">{props.errors.amount}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="reset" onClick={() => props.history.push('/services/cabletv/bouquet')}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Save</button>
            </div>
        </form>
    )
}

export default Form
