import Requests from '../../../../utils/Requests'
import { isValid, loadRoutes } from '../area.common'

const getDefaultState = () => {
    return {
        creating: false,
        loadingRoutes: false,
        area: {
            routeId: "",
            name: "",
        },
        routes: [],
        errors: {},
        showError: false,
        alertError: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const createArea = instance => {

    Requests(instance.props)
        .post()
        .withUrl("/admin/delivery/area/create")
        .withAuthorization(instance.props.auth.authorization)
        .withData(instance.state.area)
        .then(response => {
            handleCreateAreaResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleCreateAreaResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                creating: false,
                area: {
                    area: "",
                    name: "",
                },
                flag: {
                    type: "success",
                    text: "Created Successfully"
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        creating: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onCreateArea,
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        loadRoutes: () => loadRoutes(instance),
        createArea: () => createArea(instance)
    }
}

export default Service
