const columns = [
    {
        name: "0",
        label: "All",
        type: "S"
    },
    {
        name: "1",
        label: "Transaction ID",
        type: "N"
    },
    {
        name: "2",
        label: "Customer",
        type: "S"
    },
    {
        name: "3",
        label: "Type",
        type: "C",
        options: [
            {
                label: "Internet",
                value: "SKYSENX_INTERNET"
            },
            {
                label: "Cabletv",
                value: "CABLE_TV"
            },
            {
                label: "Electricity Bill",
                value: "ELECTRICITY_BILL"
            },
            {
                label: "Deliverable",
                value: "DELIVERABLE"
            },
            {
                label: "Airtime",
                value: "AIRTIME"
            },
            {
                label: "Ticket",
                value: "TICKET"
            }
        ]
    },
    {
        name: "4",
        label: "Status",
        type: "C",
        options: [
            {
                label: "Pending",
                value: "CREATED"
            },
            {
                label: "Processing",
                value: "PROCESSING"
            },
            {
                label: "Processed",
                value: "PROCESSED"
            },
            {
                label: "Cancelled",
                value: "CANCELLED"
            },
            {
                label: "On Hold",
                value: "ON_HOLD"
            }
        ]
    }
]

export default columns