import React from 'react'
import { Switch, Route } from "react-router-dom"
import Bouquets from './bouquets.page'
import Create from './create/create.page'

const BouquetController = () => {
    return (
        <div className="bouquet">
            <Switch>
                <Route path="/services/cabletv/bouquet/create" component={Create} />
                <Route path="/services/cabletv/bouquet" component={Bouquets} />
            </Switch>
        </div>
    )
}

export default BouquetController