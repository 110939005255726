import React from 'react'
import NumberFormat from 'react-number-format'
import Logo from '../../components/brands/logo/logo.component'
import StatusLabels from '../../data/status-label'

const Ticket = props => {
    return (
        <div className="ticket">
            <div className="top">
                <Logo name="ticket" />
                <NumberFormat value={props.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <h4>{value}</h4>} />
            </div>
            <div className="summary">
                <div className="info">
                    <p>Date</p>
                    <p>{new Date(props.created).toString("dd MMM yyyy")}</p>
                </div>
                <div className="info">
                    <p>Transaction ID</p>
                    <p>{props.number}</p>
                </div>
                <div className="info">
                    <p>Customer Name</p>
                    <p>{props.customer.firstname} {props.customer.lastname}</p>
                </div>
                <div className="info">
                    <p>Customer Phone</p>
                    <p>{props.customer.phone}</p>
                </div>
                <div className="info">
                    <p>Customer Email</p>
                    <p>{props.customer.email}</p>
                </div>
                <div className="info">
                    <p>Event</p>
                    <p>{props.ticket.ticketType.event.name}</p>
                </div>
                <div className="info">
                    <p>Ticket Type</p>
                    <p>{props.ticket.ticketType.name}</p>
                </div>
                <div className="info">
                    <p>Quantity</p>
                    <p>{props.ticket.quantity}</p>
                </div>
                <div className="info">
                    <p>Amount</p>
                    <NumberFormat value={props.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <p>{value}</p>} />
                </div>
                <div className="info">
                    <p>Status</p>
                    <p className="status">{StatusLabels[props.status]}</p>
                </div>
            </div>
        </div>
    )
}

export default Ticket