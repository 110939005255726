import React from 'react'
import { withRouter } from 'react-router-dom'
import Dialog from '../../components/dialog/dialog.component'
import Loading from '../../components/loading/loading.component'
import AlertDialog from '../../components/dialog/alert/alert.component'
import { connect } from 'react-redux'
import Pagination from '../../components/pagination/pagination.component'
import './wallets.styles.scss'
import Service from './wallets.service'
import NumberFormat from 'react-number-format'
import { withEmit } from 'react-emit'
import { SEARCH_REQUESTED } from '../../templates/default/header/search/search'
import { SET_COLUMNS } from '../../redux/reducers/search/action-types'
import columns from './data/filters'

class Wallets extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadWallets()
        this.props.dispatch({
            type: SET_COLUMNS,
            payload: columns
        })
        this.props.on(SEARCH_REQUESTED, this.onFilterChange);
    }

    onPageChange = page => {
        this.setState({
            ...this.state,
            currentPage: page,
            loadingWallets: true
        }, () => this.service.loadWallets())
    }

    onFilterChange = filter => {
        this.setState({
            ...this.state,
            selectedIndex: -1,
            currentPage: 1,
            filter: filter,
            loadWallets: true
        }, () => this.service.loadWallets())
    }

    render() {
        return (
            <>
                {this.state.loadingWallets && <Dialog><Loading /></Dialog>}
                {this.state.showError && <AlertDialog {...this.state.alertError} />}
                <div className="wallets">
                    <div className="header">
                        <h4>Wallets</h4>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Credit</th>
                                <th>Debit</th>
                                <th>Balance</th>
                                <th>Customer</th>
                                <th>Created</th>
                                <th>Updated</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.wallets.map((wallet, key) => <tr key={key}>
                                    <td>{wallet.credit > 0 && <NumberFormat value={wallet.credit / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} />}</td>
                                    <td>{wallet.debit > 0 && <NumberFormat value={(wallet.debit / 100)} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} />}</td>
                                    <td>{wallet.balance > 0 && <NumberFormat value={(wallet.balance / 100)} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} />}</td>
                                    <td>{wallet.customer.firstname} {wallet.customer.lastname}</td>
                                    <td>{new Date(wallet.created).toString("dd MMM yyyy HH:mm:ss")}</td>
                                    <td>{new Date(wallet.updated).toString("dd MMM yyyy HH:mm:ss")}</td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                    {
                        this.state.total > 0 && <Pagination totalItemsCount={this.state.total} currentIndex={this.state.currentPage} itemsPerPage={this.state.pageSize} onChange={this.onPageChange} />
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(withEmit(Wallets)))