import React from 'react'
import Service from './processor-setting.service'
import './processor-setting.styles.scss'

class ProcessorSetting extends React.Component {
    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    onChange = e => {
        this.setState({
            ...this.state,
            value: e.target.value
        })
    }

    onSubmit = e => {
        e.preventDefault()
        this.props.onUpdate(this.props.id, this.state.value)
    }

    

    render() {
        return (
            <div className="processor-setting">
                <form method="post" onSubmit={this.onSubmit}>
                    <div className="row">
                        <div className="form-input">
                            <label>{this.props.name}</label>
                            <select name={this.state.id} onChange={this.onChange} value={this.state.value}>
                                {
                                    this.props.options.map((option, key) => <option key={key} value={option.value}>{option.name}</option>)
                                }
                            </select>
                        </div>
                    </div>
                    <div className="actions">
                        <button type="submit">Update</button>
                    </div>
                </form>
            </div>
        )
    }
}

export default ProcessorSetting