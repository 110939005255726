import Requests from '../../../../utils/Requests'
import { isValid, loadDeliverables } from '../item.common'

const getDefaultState = () => {
    return {
        loadingItem: true,
        loadingDeliverables: true,
        saving: false,
        uploading: false,
        item: {
            deliverableId: "",
            name: "",
            category: "",
            description: "",
            amount: "",
            weight: "",
            maxOrderQuantity: ""
        },
        deliverables: [],
        showError: false,
        alertError: {},
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}
const loadItem = instance => {

    let data = {
        deliverableItemId: instance.props.match.params.id
    }

    Requests(instance.props)
        .post()
        .withUrl("/admin/vendor/deliverable-item")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleLoadItemResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadItemResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingItem: false,
                item: {
                    ...response.data.deliverableItem,
                    deliverableId: response.data.deliverableItem.deliverable.id,
                    amount: response.data.deliverableItem.amount / 100,
                    charges: response.data.deliverableItem.charges / 100
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const saveItem = instance => {

    let data = {
        ...instance.state.item,
        amount: instance.state.item.amount * 100,
        charges: instance.state.item.charges * 100
    }

    Requests(instance.props)
        .post()
        .withUrl("/admin/vendor/deliverable-item/update")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleSaveItemResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleSaveItemResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "success",
                    text: "Saved successfully"
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const uploadImage = (instance, data) => {
    Requests(instance.props)
        .post()
        .withUrl("/admin/vendor/deliverable-item/upload-image")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleUploadImageResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleUploadImageResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                uploading: false,
                item: {
                    ...instance.state.item,
                    imageUrl: response.data.imageUrl
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadingItem: false,
        saving: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Okay",
            onClick: () => instance.setState({
                ...instance.state,
                showError: false
            }),
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        isValid: () => isValid(instance),
        loadDeliverables: () => loadDeliverables(instance),
        loadItem: () => loadItem(instance),
        saveItem: () => saveItem(instance),
        uploadImage: (data) => uploadImage(instance, data)
    }
}

export default Service