import axios from 'axios'
import {API_ENDPOINT} from '../../../../config'

const getDefaultState = () => {
    return {
        loadingOrderStatusMetrics: true,
        orderStatusMetrics: {
            pending: 0,
            processing: 0,
            onHold: 0
        }
    }
}

const loadOrderStatusMetrics = instance => {

    axios({
        method: 'post',
        url: API_ENDPOINT + "/admin/order/status-metrics",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {}
    }).then(response => handleLoadOrderStatusMetricsResponse(instance, response))
        .catch(error => {
            handleError(instance, error)
        })
}

const handleLoadOrderStatusMetricsResponse = (instance, response) => {
    switch (response.data.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingOrderStatusMetrics: false,
                orderStatusMetrics: response.data.data
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            handleError(instance, response.data.status.message)
    }
}

const handleError = (instance, error) => {
    const close = () => instance.setState({
        ...instance.state,
        showError: false
    })
    
    instance.setState({
        ...instance.state,
        orders: [],
        loadingOrders: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Okay",
            onClick: close,
            onClose: close
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        loadOrderStatusMetrics: () => loadOrderStatusMetrics(instance)
    }
}

export default Service