import Requests from '../../../../utils/Requests'
import { isValid, loadRoutes } from '../area.common'

const getDefaultState = () => {
    return {
        loadingArea: true,
        loadingRoutes: true,
        saving: false,
        area: {
            routeId: "",
            name: "",
        },
        routes: [],
        showError: false,
        alertError: {},
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}
const loadArea = instance => {

    let data = {
        areaId: instance.props.match.params.id
    }

    Requests(instance.props)
        .post()
        .withUrl("/admin/delivery/area")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleLoadAreaResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadAreaResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingArea: false,
                area: {
                    id: response.data.area.id,
                    routeId: response.data.area.route.id,
                    name: response.data.area.name
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const saveArea = instance => {

    Requests(instance.props)
        .post()
        .withUrl("/admin/delivery/area/update")
        .withAuthorization(instance.props.auth.authorization)
        .withData(instance.state.area)
        .then(response => {
            handleSaveAreaResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleSaveAreaResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "success",
                    text: "Saved successfully"
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadingArea: false,
        saving: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Okay",
            onClick: () => instance.setState({
                ...instance.state,
                showError: false
            }),
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        isValid: () => isValid(instance),
        loadRoutes: () => loadRoutes(instance),
        loadArea: () => loadArea(instance),
        saveArea: () => saveArea(instance)
    }
}

export default Service