import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import { connect } from 'react-redux'
import Pagination from '../../../components/pagination/pagination.component'
import './deliverables.styles.scss'
import Service from './deliverables.service'
import iconDelete from '../../../assets/icons/delete-red.svg'
import iconEdit from '../../../assets/icons/edit-dark.svg'
import DeleteDeliverable from './components/delete/delete.component'
import NumberFormat from 'react-number-format'

class Deliverables extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadDeliverables()
    }

    onPageChange = page => {
        this.setState({
            ...this.state,
            currentPage: page
        }, () => this.service.loadDeliverables())
    }

    onFilterChange = e => {
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                value: "",
                [e.target.name]: e.target.value
            }
        })
    }

    onFilterSubmit = e => {
        e.preventDefault()
        this.service.loadDeliverables()
    }

    toggleShowDelete = deliverable => this.setState({
        ...this.state,
        showDelete: !this.state.showDelete,
        deliverableToDelete: deliverable
    })

    onDelete = () => this.setState({
        ...this.state,
        showDelete: false,
        deliverables: this.state.deliverables.filter(deliverable => deliverable.id !== this.state.deliverableToDelete.id)
    })

    getTypeLabel = type => {
        switch(type){
            case 1:
                return "Service"
            case 2:
                return "Stocked Product"
            default:
                return "Unknown"
        }
    }

    render() {
        return (
            <>
                {this.state.loadingDeliverables && <Dialog><Loading /></Dialog>}
                {this.state.showDelete && <Dialog><DeleteDeliverable deliverable={this.state.deliverableToDelete} onDelete={this.onDelete} onCancel={() => this.toggleShowDelete(null)} /></Dialog>}
                <div className="deliverables">
                    <div className="header">
                        <h4>Deliverables</h4>
                        <div className="buttons">
                            <Link className="btn" to="/marketplace/deliverable/create">Create Deliverable</Link>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Sku</th>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Vendor</th>
                                <th>Lowest Amount</th>
                                <th>Charges(%)</th>
                                <th>Updated</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.deliverables.map((deliverable, key) => <tr key={key}>
                                    <td>{deliverable.sku}</td>
                                    <td>{deliverable.name}</td>
                                    <td>{this.getTypeLabel(deliverable.type)}</td>
                                    <td>{deliverable.vendor.name}</td>
                                    <td><NumberFormat value={deliverable.lowestAmount/100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} /></td>
                                    <td>{deliverable.type === 1? deliverable.chargesInPercent : " - "}</td>
                                    <td>{new Date(deliverable.updated).toString("dd MMM yyyy HH:mm:ss")}</td>
                                    <td>
                                        <div className="actions">
                                            <img alt="" src={iconEdit} onClick={() => this.props.history.push('/marketplace/deliverable/' + deliverable.id)} />
                                            <img alt="" src={iconDelete} onClick={() => this.toggleShowDelete(deliverable)} />
                                        </div>
                                    </td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                    {
                        this.state.total > 0 && <Pagination totalItemsCount={this.state.total} currentIndex={this.state.currentPage} itemsPerPage={this.state.pageSize} onChange={this.onPageChange} />
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Deliverables))