import React from 'react'

const Form = (props) => {
    return (
        <form method="post" onSubmit={props.onSubmit}>
            <div className="inputs">
                <div className="row" id="reference">
                    <div className="form-input">
                        <label>Reference</label>
                        <input type="text" name="reference" value={props.reference} onChange={props.onChange} />
                        <span className="error">{props.errors.reference}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="reset" onClick={() => props.history.push('/cabletv/package/all')}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Requery</button>
            </div>
        </form>
    )
}

export default Form
