import React from 'react'
import { withRouter } from 'react-router-dom'

const Form = (props) => {
    return (
        <form method="post" onSubmit={props.onSubmit}>
            <div className="inputs">
                <div className="row" id="name">
                    <div className="form-input">
                        <label>Name</label>
                        <input type="text" name="name" value={props.pricing.name} onChange={props.onChange} />
                        <span className="error">{props.errors.name}</span>
                    </div>
                </div>
                <div className="row" id="minimumAmount">
                    <div className="form-input">
                        <label>Minimum Amount</label>
                        <input type="text" name="minimumAmount" value={props.pricing.minimumAmount} onChange={props.onChange} />
                        <span className="error">{props.errors.minimumAmount}</span>
                    </div>
                </div>
                <div className="row" id="amountPerWeight">
                    <div className="form-input">
                        <label>Amount Per Weight</label>
                        <input type="text" name="amountPerWeight" value={props.pricing.amountPerWeight} onChange={props.onChange} />
                        <span className="error">{props.errors.amountPerWeight}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="reset" onClick={() => props.history.push('/delivery/pricing/all')}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Save</button>
            </div>
        </form>
    )
}

export default withRouter(Form)
