import Validation from '../../../data/validation/validation'
import { NumberValidationRule, StringValidationRule } from '../../../data/validation/rules'

export const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.pricing.name, (error) => errors.name = error, { min: { value: 2, error: "Invalid" }, max: { value: 20, error: "Invalid" } })
    validation.addValidationRule(NumberValidationRule, instance.state.pricing.minimumAmount, (error) => errors.minimumAmount = error, { min: { value: 0, error: "Invalid" }, max: { value: 100000, error: "Invalid" } })
    validation.addValidationRule(NumberValidationRule, instance.state.pricing.amountPerWeight, (error) => errors.amountPerWeight = error, { min: { value: 0, error: "Invalid" }, max: { value: 100000, error: "Invalid" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Error"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}
