import Requests from '../../../../utils/Requests'
import { isValid } from '../pricing.common'

const getDefaultState = () => {
    return {
        creating: false,
        pricing: {
            name: "",
            minimumAmount: "",
            amountPerWeight: ""
        },
        errors: {},
        showError: false,
        alertError: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const createPricing = instance => {

    let data = {
        ...instance.state.pricing,
        minimumAmount: instance.state.pricing.minimumAmount * 100,
        amountPerWeight: instance.state.pricing.amountPerWeight * 100
    }

    Requests(instance.props)
        .post()
        .withUrl("/admin/delivery/pricing-class/create")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleCreatePricingResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleCreatePricingResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.props.history.push('/delivery/pricing/all')
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        creating: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onCreatePricing,
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        createPricing: () => createPricing(instance)
    }
}

export default Service
