import React from "react"
import iconMtn from '../../../../../assets/icons/mtn-white.svg'
import iconAirtel from '../../../../../assets/icons/airtel-white.svg'
import iconGlo from '../../../../../assets/icons/glo-white.svg'
import icon9Mobile from '../../../../../assets/icons/9mobile-white.svg'
import iconDstv from '../../../../../assets/icons/dstv-white.svg'
import iconGotv from '../../../../../assets/icons/gotv.svg'
import iconStartimes from '../../../../../assets/icons/startimes.svg'
import iconSkysenxInternet from '../../../../../assets/icons/internet-skysenx.svg'
import iconBedc from '../../../../../assets/icons/bedc-only.svg'
import iconEKEDC from '../../../../../assets/icons/ekedc.svg'
import iconIBEDC from '../../../../../assets/icons/ibedc.svg'
import iconIKEDC from '../../../../../assets/icons/ikedc.svg'
import iconJED from '../../../../../assets/icons/jed.svg'
import iconKEDCO from '../../../../../assets/icons/kedco.svg'
import iconKAEDCO from '../../../../../assets/icons/kaedco.svg'
import iconPHED from '../../../../../assets/icons/phed.svg'
import iconAEDC from '../../../../../assets/icons/aedc.svg'
import iconShawarma from '../../../../../assets/images/bryankourtny.jpg'
import iconTicket from '../../../../../assets/icons/ticket.svg'

import './logo.styles.scss'

const Logo = props => {
    let logoInfo = getLogoInfo(props.name)
    return (
        <div className={"logo " + logoInfo.class}>
            <img src={logoInfo.src} alt="icon" />
        </div>
    )
}

const getLogoInfo = name => {
    switch (name) {
        case "mtn":
            return {
                src: iconMtn,
                class: "mtn"
            }
        case "9mobile":
            return {
                src: icon9Mobile,
                class: "_9mobile"
            }
        case "airtel":
            return {
                src: iconAirtel,
                class: "airtel"
            }
        case "glo":
            return {
                src: iconGlo,
                class: "glo"
            }
        case "dstv":
            return {
                src: iconDstv,
                class: "dstv"
            }
        case "gotv":
            return {
                src: iconGotv,
                class: "gotv"
            }
        case "startimes":
            return {
                src: iconStartimes,
                class: "startimes"
            }
        case "skysenx-internet":
            return {
                src: iconSkysenxInternet,
                class: "skysenx-internet"
            }
        case "shawarma":
            return {
                src: iconShawarma,
                class: "shawarma"
            }
        case "bedc":
            return {
                src: iconBedc,
                class: "distributor"
            }
        case "ikedc":
            return {
                src: iconIKEDC,
                class: "distributor"
            }
        case "ekedc":
            return {
                src: iconEKEDC,
                class: "distributor"
            }
        case "phed":
            return {
                src: iconPHED,
                class: "distributor"
            }
        case "aedc":
            return {
                src: iconAEDC,
                class: "distributor"
            }
        case "kedco":
            return {
                src: iconKEDCO,
                class: "distributor"
            }
        case "jed":
            return {
                src: iconJED,
                class: "distributor"
            }
        case "ibedc":
            return {
                src: iconIBEDC,
                class: "distributor"
            }
        case "kaedco":
            return {
                src: iconKAEDCO,
                class: "distributor"
            }
        case "ticket":
            return {
                src: iconTicket,
                class: "ticket"
            }
        default:
            return {
                src: icon9Mobile,
                class: "_9mobile"
            }
    }
}

export default Logo