import React from 'react'
import { withRouter } from 'react-router-dom'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import { connect } from 'react-redux'
import Service from './processor-settings.service'
import ProcessorSetting from './processor-setting/processor-settings.component'

class ProcessorSettings extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadProcessorSettings()
    }

    onUpdate = (id, value) => this.service.updateProcessorSettings(id, value)

    render() {
        return (
            <>
                {(this.state.loadingProcessorSettings || this.state.updateProcessorSettings) && <Dialog><Loading /></Dialog>}
                <div className="processor-settings">
                    <div className="header">
                        <h4>Processor Settings</h4>
                    </div>
                    <div className="items">
                        {
                            this.state.processorSettings.map((processorSetting, key) => <ProcessorSetting key={key} onUpdate={this.onUpdate} {...processorSetting} />)
                        }
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(ProcessorSettings))