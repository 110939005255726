import React from 'react'

const TransactionForm = (props) => {
    return (
        <form method="post" onSubmit={props.onSubmit}>
            <div className="inputs">
                <div className="row" id="source">
                    <div className="form-input">
                        <label>Source</label>
                        <select name="source" onChange={props.onChange} value={props.source}>
                            <option value=""></option>
                            <option value="3">Credit Note</option>
                            <option value="1">Debit Note</option>
                        </select>
                        <span className="error">{props.errors.source}</span>
                    </div>
                </div>
                <div className="row" id="username">
                    <div className="form-input">
                        <label>Customer's Email</label>
                        <input type="email" name="customerEmail" value={props.customerEmail} onChange={props.onChange} />
                        <span className="error">{props.errors.customerEmail}</span>
                    </div>
                </div>
                <div className="row" id="type">
                    <div className="form-input">
                        <label>Type</label>
                        <select name="type" onChange={props.onChange} value={props.type}>
                            <option value=""></option>
                            <option value="0">Credit</option>
                            <option value="1">Debit</option>
                        </select>
                        <span className="error">{props.errors.type}</span>
                    </div>
                </div>
                <div className="row" id="amount">
                    <div className="form-input">
                        <label>Amount</label>
                        <input type="number" name="amount" value={props.amount} onChange={props.onChange} />
                        <span className="error">{props.errors.amount}</span>
                    </div>
                </div>
                <div className="row" id="description">
                    <div className="form-input">
                        <label>Description</label>
                        <input type="text" name="description" value={props.description} onChange={props.onChange} />
                        <span className="error">{props.errors.description}</span>
                    </div>
                </div>
                <div className="row" id="refId">
                    <div className="form-input">
                        <label>Reference</label>
                        <input type="text" name="refId" value={props.refId} onChange={props.onChange} />
                        <span className="error">{props.errors.refId}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="reset" onClick={() => props.history.push('/wallet/transaction/all')}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Save</button>
            </div>
        </form>
    )
}

export default TransactionForm
