import Requests from '../../../../../utils/Requests'
import { IsValid, loadBaxiBouquets, loadVTPassBouquets } from '../common'

const getDefaultState = () => {
    return {
        creating: false,
        loadingVTPassBouquets: true,
        loadingBaxiBouquets: true,
        vtpassBouquets: [],
        baxiBouquets: [],
        type: "",
        amount: "",
        name: "",
        vtpassCabletvBouquetCode: "",
        baxiCabletvBouquetCode: "",
        errors: {},
        showError: false,
        alertError: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const createBouquet = instance => {

    let data = {
        type: Number(instance.state.type),
        name: instance.state.name,
        amount: instance.state.amount * 100,
        vtpassCabletvBouquetCode: instance.state.vtpassCabletvBouquetCode,
        baxiCabletvBouquetCode: instance.state.baxiCabletvBouquetCode
    }

    Requests(instance.props)
        .post()
        .withUrl("/admin/services/cabletv/bouquet/create")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleCreateBouquetResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleCreateBouquetResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.props.history.push('/services/cabletv/bouquet')
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        creating: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onCreateBouquet,
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => IsValid(instance),
        loadBaxiBouquets: () => loadBaxiBouquets(instance),
        loadVTPassBouquets: () => loadVTPassBouquets(instance),
        createBouquet: () => createBouquet(instance)
    }
}

export default Service
