import Requests from '../../../../utils/Requests'
import { isValid } from '../pricing.common'

const getDefaultState = () => {
    return {
        loadingPricing: true,
        saving: false,
        pricing: {
            name: "",
            minimumAmount: "",
            amountPerWeight: ""
        },
        showError: false,
        alertError: {},
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}
const loadPricing = instance => {

    let data = {
        pricingClassId: instance.props.match.params.id
    }

    Requests(instance.props)
        .post()
        .withUrl("/admin/delivery/pricing-class")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleLoadPricingResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadPricingResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingPricing: false,
                pricing: {
                    ...response.data.pricingClass,
                    minimumAmount: response.data.pricingClass.minimumAmount/100,
                    amountPerWeight: response.data.pricingClass.amountPerWeight/100
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const savePricing = instance => {

    let data = {
        ...instance.state.pricing,
        minimumAmount: instance.state.pricing.minimumAmount * 100,
        amountPerWeight: instance.state.pricing.amountPerWeight * 100
    }

    Requests(instance.props)
        .post()
        .withUrl("/admin/delivery/pricing-class/update")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleSavePricingResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleSavePricingResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "success",
                    text: "Saved successfully"
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadingPricing: false,
        saving: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Okay",
            onClick: () => instance.setState({
                ...instance.state,
                showError: false
            }),
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        isValid: () => isValid(instance),
        loadPricing: () => loadPricing(instance),
        savePricing: () => savePricing(instance)
    }
}

export default Service