import React from 'react'
import { Switch, Route } from "react-router-dom"
import Orders from './orders.page'
import OrderDetails from './details/details.page'

const OrderController = () => {
    return (
        <div className="order">
            <Switch>
                <Route path="/order/all" component={Orders} />
                <Route path="/order/:id" component={OrderDetails} />
            </Switch>
        </div>
    )
}

export default OrderController