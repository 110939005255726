import Requests from '../../../../utils/Requests'
import Validation from '../../../../data/validation/validation'
import { NumberValidationRule, StringValidationRule } from '../../../../data/validation/rules'

const getDefaultState = () => {
    return {
        creating: false,
        loadingItems: false,
        inventory: {
            deliverableItemId: "",
            quantity: "",
            reference: ""
        },
        items: [],
        errors: {},
        showError: false,
        alertError: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadItems = instance => {
    instance.setState({ ...instance.state, loadingItems: true })
    let data = {
        offset: 0,
        maxSize: 1000,
    }
    Requests(instance.props)
        .post()
        .withUrl("/admin/vendor/deliverable-items")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleLoadItemsResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadItemsResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingItems: false,
                items: response.data.deliverableItems.filter(item => item.deliverable.type === 2),
                flag: {
                    type: "success",
                    text: "Created Successfully"
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.inventory.deliverableItemId, (error) => errors.deliverableItemId = error, { min: { value: 2, error: "Invalid" }, max: { value: 36, error: "Invalid" } })
    validation.addValidationRule(NumberValidationRule, instance.state.inventory.quantity, (error) => errors.quantity = error, { min: { value: -10000, error: "Invalid" }, max: { value: 10000, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.inventory.reference, (error) => errors.reference = error, { min: { value: 0, error: "Invalid" }, max: { value: 100, error: "Invalid" } })
    
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Error"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const createStockInOut = instance => {

    Requests(instance.props)
        .post()
        .withUrl("/admin/vendor/deliverable-inventory-item/stock-in-out")
        .withAuthorization(instance.props.auth.authorization)
        .withData(instance.state.inventory)
        .then(response => {
            handleCreateStockInOutResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleCreateStockInOutResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                creating: false,
                inventory: {
                    deliverableItemId: "",
                    quantity: "",
                    reference: ""
                },
                flag: {
                    type: "success",
                    text: "Created Successfully"
                }
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        creating: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onCreateDeliverable,
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        loadItems: () => loadItems(instance),
        createStockInOut: () => createStockInOut(instance)
    }
}

export default Service
