import axios from 'axios'
import {API_ENDPOINT} from '../../config'
import queryString from 'query-string'

const getDefaultState = instance => {
    return {
        loadingOrders: true,
        orders: [],
        filter: getFilter(instance),
        showError: false,
        alertError: {},
        selectedIndex: -1,
        currentPage: 1,
        pageSize: 10,
        total: 0
    }
}

const getFilter = instance => {
    let query = queryString.parse(instance.props.location.search);
    if(typeof query.filter === "undefined" || typeof query.value === "undefined"){
        return {
            criteria: 0,
            value: ""
        }
    }
    return {
        criteria: query.filter,
        value: query.value
    }
}

const loadOrders = instance => {
    let data = {
        offset: instance.state.currentPage - 1,
        maxSize: instance.state.pageSize,
        filter: {
            ...instance.state.filter,
            criteria: Number(instance.state.filter.criteria)
        }
    }

    axios({
        method: 'post',
        url: API_ENDPOINT + "/admin/orders",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: data
    }).then(response => handleLoadOrdersResponse(instance, response))
        .catch(error => {
            handleError(instance, error)
        })
}

const handleLoadOrdersResponse = (instance, response) => {
    switch (response.data.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingOrders: false,
                orders: response.data.data.orders,
                total: response.data.data.total
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            handleError(instance, response.data.status.message)
    }
}

const handleError = (instance, error) => {
    const close = () => instance.setState({
        ...instance.state,
        showError: false
    })
    
    instance.setState({
        ...instance.state,
        orders: [],
        loadingOrders: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Okay",
            onClick: close,
            onClose: close
        }
    })
}

const Service = instance => {
    return {
        loadOrders: () => loadOrders(instance),
        getDefaultState: () => getDefaultState(instance)
    }
}

export default Service