import axios from 'axios'
import {API_ENDPOINT} from '../../config'

const getDefaultState = () => {
    return {
        loadingWallets: true,
        wallets: [],
        filter: {
            criteria: 0,
            value: ""
        },
        showError: false,
        alertError: {},
        selectedIndex: -1,
        currentPage: 1,
        pageSize: 10,
        total: 0
    }
}

const loadWallets = instance => {
    let data = {
        offset: instance.state.currentPage - 1,
        maxSize: instance.state.pageSize,
        filter: {
            ...instance.state.filter,
            criteria: Number(instance.state.filter.criteria)
        }
    }
    
    axios({
        method: 'post',
        url: API_ENDPOINT + "/admin/billing/wallets",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: data
    }).then(response => handleLoadWalletsResponse(instance, response))
        .catch(error => {
            handleError(instance, error)
        })
}

const handleLoadWalletsResponse = (instance, response) => {
    switch (response.data.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingWallets: false,
                wallets: response.data.data.wallets,
                total: response.data.data.total
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            handleError(instance, response.data.status.message)
    }
}

const handleError = (instance, error) => {
    const close = () => instance.setState({
        ...instance.state,
        showError: false
    })
    
    instance.setState({
        ...instance.state,
        wallets: [],
        loadingWallets: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Okay",
            onClick: close,
            onClose: close
        }
    })
}

const Service = instance => {
    return {
        loadWallets: () => loadWallets(instance),
        getDefaultState: () => getDefaultState(instance)
    }
}

export default Service