import React from 'react'
import { withEmit } from "react-emit"
import { connect } from 'react-redux'
import iconSearch from '../../../../assets/icons/search.svg'
import './search.scss'
import store from '../../../../redux/stores/store'

export const SEARCH_CHANGED = "search_changed"
export const SEARCH_REQUESTED = "search_requested"

class Search extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            columns: props.search.columns,
            filter: {
                criteria: "",
                value: ""
            }
        }
    }

    componentDidMount() {
        this.unsubscribe = store.subscribe(() => {
            this.setState({
               ...this.state,
                columns: store.getState().search.columns
           })
        })
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                value: "",
                [e.target.name]: e.target.value
            }
        })
        this.props.emit(SEARCH_CHANGED, e.target.value.toLowerCase());
    }

    onCriteriaValueChange = (e) => {
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                [e.target.name]: e.target.value
            }
        }, () => {
            if (this.state.filter.value.length > 0) {
                this.props.emit(SEARCH_REQUESTED, this.state.filter);
            }
        })
        this.props.emit(SEARCH_CHANGED, e.target.value.toLowerCase());
    }

    onSubmit = (e) => {
        e.preventDefault()
        if (this.state.filter.criteria.length > 0) {
            this.props.emit(SEARCH_REQUESTED, this.state.filter);
        }
    }

    getSelectedColumn = column => {
        let columnConfig = this.state.columns.filter(col => col.name === column)
        if (columnConfig.length > 0) {
            return columnConfig[0]
        }
        return null
    }

    renderFilterInput = selectedColumn => {
        if (selectedColumn == null) {
            return <input type="text" autoComplete="off" name="value" onChange={this.onChange} value={this.state.filter.value} />
        }
        switch (selectedColumn.type) {
            case 'D':
                return <input type="date" autoComplete="off" name="value" onChange={this.onChange} value={this.state.filter.value} />
            case 'N':
                return <input type="number" autoComplete="off" name="value" onChange={this.onChange} value={this.state.filter.value} />
            case 'C':
                return <select name="value" defaultValue={this.state.filter.value} onChange={this.onCriteriaValueChange}>
                    <option value=""></option>
                    {
                        selectedColumn.options.map((option, key) => <option key={key} value={typeof option === 'object' ? option.value : option}>{typeof option === 'object' ? option.label : option}</option>)
                    }
                </select>
            default:
                return <input type="text" autoComplete="off" name="value" onChange={this.onChange} value={this.state.filter.value} />
        }
    }

    render() {
        let selectedColumn = this.getSelectedColumn(this.state.filter.criteria)
        return (
            <form className="search" onSubmit={this.onSubmit}>
                <select name="criteria" onChange={this.onChange} defaultValue={this.state.filter.criteria}>
                    <option value="">Select Filter</option>
                    {
                        this.state.columns.map((column, key) => <option key={key} value={column.name}>{column.label}</option>)
                    }
                </select>
                {
                    this.renderFilterInput(selectedColumn)
                }
                {
                    (selectedColumn === null || selectedColumn.type !== 'C') && <img src={iconSearch} alt=""/>
                }
            </form>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        search: state.search
    }
}

export default connect(mapStateToProps)(withEmit(Search))