import Requests from '../../../../../utils/Requests'

const getDefaultState = () => {
    return {
        deleting: false,
        showError: false,
        alertError: {}
    }
}

const deletePricing = (instance) => {
    
    let data = {
        pricingClassId: instance.props.pricing.id
    }

    Requests(instance.props)
        .post()
        .withUrl("/admin/delivery/pricing-class/delete")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleDeletePricingResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleDeletePricingResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.props.onDelete()
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        deleting: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onDelete,
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        deletePricing: () => deletePricing(instance)
    }
}

export default Service