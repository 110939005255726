import React from 'react'
import NumberFormat from 'react-number-format'
import Logo from '../../components/brands/logo/logo.component'
import StatusLabels from '../../data/status-label'

const Cabletv = props => {
    return (
        <div className="cabletv">
            <div className="top">
                {getLogo(props.cabletv.decoder.type)}
                <NumberFormat value={props.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <h4>{value}</h4>} />
            </div>
            <div className="summary">
                <div className="info">
                    <p>Date</p>
                    <p>{new Date(props.created).toString("dd MMM yyyy")}</p>
                </div>
                <div className="info">
                    <p>Transaction ID</p>
                    <p>{props.number}</p>
                </div>
                <div className="info">
                    <p>Customer Name</p>
                    <p>{props.customer.firstname} {props.customer.lastname}</p>
                </div>
                <div className="info">
                    <p>Customer Phone</p>
                    <p>{props.customer.phone}</p>
                </div>
                <div className="info">
                    <p>Customer Email</p>
                    <p>{props.customer.email}</p>
                </div>
                <div className="info">
                    <p>Decoder Number</p>
                    <p>{props.cabletv.decoder.number}</p>
                </div>
                <div className="info">
                    <p>Decoder Name</p>
                    <p>{props.cabletv.decoder.name}</p>
                </div>
                <div className="info">
                    <p>Nickname</p>
                    <p>{props.cabletv.decoder.alias}</p>
                </div>
                <div className="info">
                    <p>Amount</p>
                    <NumberFormat value={props.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <p>{value}</p>} />
                </div>
                <div className="info">
                    <p>Status</p>
                    <p className="status">{StatusLabels[props.status]}</p>
                </div>
            </div>
        </div>
    )
}

const getLogo = type => {
    switch (type) {
        case 1:
            return <Logo name="gotv" />
        case 2:
            return <Logo name="startimes" />
        default:
            return <Logo name="dstv" />
    }
}

export default Cabletv