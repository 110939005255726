import axios from 'axios'
import {API_ENDPOINT} from '../../../../config'

const getDefaultState = () => {
    return {
        loadingOrderTypeMetrics: true,
        orderTypeMetrics: [],
        days: 7,
        type: 0
    }
}

const loadOrderTypeMetrics = instance => {
    let data = {
        days: instance.state.days,
        type: instance.state.type
    }

    axios({
        method: 'post',
        url: API_ENDPOINT + "/admin/order/metrics",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: data
    }).then(response => handleLoadOrderTypeMetricsResponse(instance, response))
        .catch(error => {
            handleError(instance, error)
        })
}

const handleLoadOrderTypeMetricsResponse = (instance, response) => {
    switch (response.data.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingOrderTypeMetrics: false,
                orderTypeMetrics: response.data.data.orderTypeMetrics
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            handleError(instance, response.data.status.message)
    }
}

const handleError = (instance, error) => {
    const close = () => instance.setState({
        ...instance.state,
        showError: false
    })
    
    instance.setState({
        ...instance.state,
        orders: [],
        loadingOrders: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Okay",
            onClick: close,
            onClose: close
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        loadOrderTypeMetrics: () => loadOrderTypeMetrics(instance)
    }
}

export default Service