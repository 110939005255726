import React from 'react'
import { Switch, Route } from "react-router-dom"
import PaystackTransaction from './paystack-transaction/paystack-transaction.page'
import './common.styles.scss'

const ToolsController = () => {
    return (
        <div className="tools">
            <Switch>
                <Route path="/tools/paystack-transaction/requery" component={PaystackTransaction} />
            </Switch>
        </div>
    )
}

export default ToolsController