import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Dialog from '../../../components/dialog/dialog.component'
import AlertDialog from '../../../components/dialog/alert/alert.component'
import Loading from '../../../components/loading/loading.component'
import { connect } from 'react-redux'
import Pagination from '../../../components/pagination/pagination.component'
import './transactions.styles.scss'
import Service from './transactions.service'
import NumberFormat from 'react-number-format'
import { withEmit } from 'react-emit'
import { SEARCH_REQUESTED } from '../../../templates/default/header/search/search'
import { SET_COLUMNS } from '../../../redux/reducers/search/action-types'
import columns from './data/filters'

class Transactions extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadTransactions()
        this.props.dispatch({
            type: SET_COLUMNS,
            payload: columns
        })
        this.props.on(SEARCH_REQUESTED, this.onFilterChange);
    }

    onPageChange = page => {
        this.setState({
            ...this.state,
            currentPage: page,
            loadTransactions: true
        }, () => this.service.loadTransactions())
    }

    onFilterChange = filter => {
        this.setState({
            ...this.state,
            selectedIndex: -1,
            currentPage: 1,
            filter: filter,
            loadTransactions: true
        }, () => this.service.loadTransactions())
    }

    getSourceLabel = source => {
        switch (source) {
            case 0:
                return "Wallet Fund"
            case 1:
                return "Debit Note"
            case 2:
                return "Order"
            case 3:
                return "Credit Note"
            case 4:
                return "Cashback"
            default:
                return "Unknown"
        }
    }

    getTypeLabel = type => type === 0 ? "Credit" : "Debit"

    render() {
        return (
            <>
                {this.state.loadingTransactions && <Dialog><Loading /></Dialog>}
                {this.state.showError && <AlertDialog {...this.state.alertError} />}
                <div className="transactions">
                    <div className="header">
                        <h4>Transactions</h4>
                        <div className="buttons">
                            <Link className="btn" to="/wallet/transaction/create">Create Transaction</Link>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Amount</th>
                                <th>Type</th>
                                <th>Source</th>
                                <th>Description</th>
                                <th>Customer</th>
                                <th>Created</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.transactions.map((transaction, key) => <tr key={key}>
                                    <td><NumberFormat value={transaction.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} /></td>
                                    <td>{this.getTypeLabel(transaction.type)}</td>
                                    <td>{this.getSourceLabel(transaction.source)}</td>
                                    <td>{transaction.description}</td>
                                    <td>{transaction.customer.firstname} {transaction.customer.lastname}</td>
                                    <td>{new Date(transaction.created).toString("dd MMM yyyy HH:mm:ss")}</td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                    {
                        this.state.total > 0 && <Pagination totalItemsCount={this.state.total} currentIndex={this.state.currentPage} itemsPerPage={this.state.pageSize} onChange={this.onPageChange} />
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(withEmit(Transactions)))