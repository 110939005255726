import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Dialog from '../../../../components/dialog/dialog.component'
import AlertDialog from '../../../../components/dialog/alert/alert.component'
import Loading from '../../../../components/loading/loading.component'
import Service from './daily-report.service'
import './daily-report.styles.scss'
import NumberFormat from 'react-number-format'

class DailyReport extends React.Component {
    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadDailyReports()
    }

    render() {
        return (
            <div className="daily-report">
                {this.state.loadingDailyReports && <Dialog><Loading /></Dialog>}
                {this.state.showError && <AlertDialog {...this.state.alertError} />}

                <div className="chart">
                    <table>
                        <thead>
                            <tr>
                                <th>Source</th>
                                <th>Amount</th>
                                <th>Charges</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.dailyReports.map((dailyReport, key) => <tr key={key}>
                                    <td>{SourceLabels[dailyReport.source]}</td>
                                    <td>{<NumberFormat value={(dailyReport.amount) / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} />}</td>
                                    <td>{<NumberFormat value={dailyReport.fees / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} />}</td>
                                    <td>{new Date(dailyReport.transactionDate).toString("dd/MM/yyyy")}</td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div>

                <div className='controls'>
                    <h4>Daily Report</h4>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

const SourceLabels = [
    "Paystack", "Sales", "Cashback", "Credit Note", "Debit Note"
]

export default connect(mapStateToProps)(withRouter(DailyReport))