import React from 'react'
import { Switch, Route } from "react-router-dom"
import PayoutController from './payout/payout.controller'
import ProcessorSettingsPage from './processor-settings/processor-settings.page'
import './common.styles.scss'

const VendorController = () => {
    return (
        <div className="vendor">
            <Switch>
                <Route path="/vendor/payout" component={PayoutController} />
                <Route path="/vendor/processor-settings" component={ProcessorSettingsPage} />
            </Switch>
        </div>
    )
}

export default VendorController