import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Service from './edit.service'
import './edit.styles.scss'
import Dialog from '../../../../components/dialog/dialog.component'
import Loading from '../../../../components/loading/loading.component'
import Form from '../components/form/form'
import FormHelper from '../../../../utils/FormHelper'
import AlertDialog from '../../../../components/dialog/alert/alert.component'

class Edit extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadPricing()
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.service.isValid() && this.setState({
            ...this.state,
            saving: true
        }, () => this.service.savePricing())
    }

    render() {
        return (
            <div className="edit">
                {this.state.creating && <Dialog><Loading></Loading></Dialog>}
                {this.state.showError && <AlertDialog {...this.state.alertError} />}
                {(this.state.saving || this.state.loadingPricing) && <Dialog><Loading /></Dialog>}
                <div className="header">
                    <h4>Edit Pricing</h4>
                </div>
                <div className="card">
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <Form {...this.state} onChange={FormHelper(this, "pricing").onChange} onSubmit={this.onSubmit} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Edit))