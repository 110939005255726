import Requests from "../../../../utils/Requests"

const getDefaultState = () => {
    return {
        loadingReceipt: false,
        receipt: null,
        showError: false,
        alertError: {}
    }
}

const loadReceipt = instance => {
    Requests(instance.props)
        .post()
        .withUrl("/admin/order/utility/electricity/receipt")
        .withAuthorization(instance.props.auth.authorization)
        .withData({orderId: instance.props.id})
        .then(response => {
            handleLoadReceiptResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadReceiptResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingReceipt: false
            })
            window.open(response.data.receiptURL)
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadingReceipt: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onLoadReceipt,
            onClose: () => instance.props.history.push('/dashboard')
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadReceipt: () => loadReceipt(instance)
    }
}

export default Service