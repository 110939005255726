import React from 'react'
import { Switch, Route } from "react-router-dom"
import BouquetController from './bouquet/bouquet.controller'
import './common.styles.scss'

const CabletvController = () => {
    return (
        <div className="cabletv">
            <Switch>
                <Route path="/services/cabletv/bouquet" component={BouquetController} />
            </Switch>
        </div>
    )
}

export default CabletvController