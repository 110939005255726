import Requests from '../../../../utils/Requests'
import { isValid } from '../route.common'

const getDefaultState = () => {
    return {
        creating: false,
        route: {
            name: "",
            description: "",
        },
        errors: {},
        showError: false,
        alertError: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const createRoute = instance => {

    Requests(instance.props)
        .post()
        .withUrl("/admin/delivery/route/create")
        .withAuthorization(instance.props.auth.authorization)
        .withData(instance.state.route)
        .then(response => {
            handleCreateRouteResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleCreateRouteResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.props.history.push('/delivery/route/all')
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        creating: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Retry",
            onClick: instance.onCreateRoute,
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        createRoute: () => createRoute(instance)
    }
}

export default Service
