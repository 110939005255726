import { SET_COLUMNS } from './action-types'

const Reducer = (state = [], action) => {
    switch (action.type) {
        case SET_COLUMNS:
            return setColumns(state, action.payload)
        default:
            return state
    }
}

const setColumns = (state, payload) => {
    state.columns = payload
    return state
}

export default Reducer