const getDefaultState = instance => {
    return {
        loadingProcessorSettings: false,
        value: instance.props.value
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance)
    }
}

export default Service