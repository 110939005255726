import React from 'react'
import './paystack-transaction.styles.scss'
import { withRouter } from 'react-router-dom'
import Service from './paystack-transaction.service'
import FormHelper from '../../../utils/FormHelper'
import { connect } from 'react-redux'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import '../common.styles.scss'
import AlertDialog from '../../../components/dialog/alert/alert.component'
import Form from './form.component'

class PaystackTransaction extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    onSubmit = e => {
        e.preventDefault()
        this.service.isValid() && this.onRequeryPaystackTransaction()
    }

    onRequeryPaystackTransaction = () => this.setState({
        ...this.state,
        querying: true,
        showError: false,
        flag: {
            type: "",
            text: ""
        }
    }, () => this.service.requeryPaystackTransaction())

    render() {
        return (
            <>
                {this.state.querying && <Dialog><Loading></Loading></Dialog>}
                {this.state.showError && <AlertDialog {...this.state.alertError} />}
                <div className="paystack-transaction">
                    <div className="header">
                        <h4>Requery Paystack Transaction</h4>
                    </div>
                    <div className="card">
                        <div className={"flag " + this.state.flag.type}>
                            {this.state.flag.text}
                        </div>
                        <Form {...this.state} onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} />
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(PaystackTransaction))
