import Validation from '../../../data/validation/validation'
import { StringValidationRule } from '../../../data/validation/rules'
import Requests from '../../../utils/Requests'

export const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.deliveryOption.areaId, (error) => errors.areaId = error, { min: { value: 5, error: "Invalid" }, max: { value: 36, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.deliveryOption.pricingClassId, (error) => errors.pricingClassId = error, { min: { value: 5, error: "Invalid" }, max: { value: 36, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.deliveryOption.name, (error) => errors.name = error, { min: { value: 1, error: "Invalid" }, max: { value: 20, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.deliveryOption.description, (error) => errors.description = error, { min: { value: 0, error: "Invalid" }, max: { value: 100, error: "Invalid" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Error"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}


export const loadAreas = instance => {
    
    let data = {
        offSet: 1,
        maxSize: 1000
    }

    Requests(instance.props)
        .post()
        .withUrl("/admin/delivery/areas")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleLoadAreaResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadAreaResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingAreas: false,
                areas: response.data.areas,
                total: response.data.size
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

export const loadPricings = instance => {

    let data = {
        offSet: 1,
        maxSize: 1000
    }

    Requests(instance.props)
        .post()
        .withUrl("/admin/delivery/pricing-classes")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleLoadPricingResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadPricingResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingPricings: false,
                pricings: response.data.pricingClasses,
                total: response.data.size
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadAreas: false,
        loadPricings: false,
        creating: false,
        saving: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Okay",
            onClick: () => instance.setState({
                ...instance.state,
                showError: false
            }),
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}