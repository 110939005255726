import React from 'react'
import NumberFormat from 'react-number-format'

const Item = props => {
    return (
        <tr>
            <td>{props.description}</td>
            <td><NumberFormat value={props.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <span>{value}</span>} /></td>
        </tr>
    )
}

export default Item