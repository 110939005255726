import axios from 'axios'
import {API_ENDPOINT} from '../../../../config'

const getDefaultState = () => {
    return {
        loadingDailyReports: true,
        dailyReports: []
    }
}

const loadDailyReports = instance => {

    axios({
        method: 'post',
        url: API_ENDPOINT + "/admin/billing/daily-reports",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {}
    }).then(response => handleLoadDailyReportsResponse(instance, response))
        .catch(error => {
            handleError(instance, error)
        })
}

const handleLoadDailyReportsResponse = (instance, response) => {
    switch (response.data.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingDailyReports: false,
                dailyReports: response.data.data.dailyReports
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            handleError(instance, response.data.status.message)
    }
}

const handleError = (instance, error) => {
    const close = () => instance.setState({
        ...instance.state,
        showError: false
    })
    
    instance.setState({
        ...instance.state,
        orders: [],
        loadingOrders: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Okay",
            onClick: close,
            onClose: close
        }
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        loadDailyReports: () => loadDailyReports(instance)
    }
}

export default Service