import React from 'react'
import { Switch, Route } from "react-router-dom"
import Deliverables from './deliverables.page'
import Create from './create/create.page'
import Edit from './edit/edit.page'

const DeliverableController = () => {
    return (
        <div className="deliverable">
            <Switch>
                <Route path="/marketplace/deliverable/create" component={Create} />
                <Route path="/marketplace/deliverable/all" component={Deliverables} />
                <Route path="/marketplace/deliverable/:id" component={Edit} />
            </Switch>
        </div>
    )
}

export default DeliverableController