import Requests from '../../../../utils/Requests'
import Validation from '../../../../data/validation/validation'
import { NumberValidationRule, StringValidationRule } from '../../../../data/validation/rules'

export const IsValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.type, (error) => errors.type = error, { min: { value: 1, error: "Invalid" }, max: { value: 1, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.name, (error) => errors.name = error, { min: { value: 2, error: "Invalid" }, max: { value: 300, error: "Invalid" } })
    validation.addValidationRule(NumberValidationRule, instance.state.amount, (error) => errors.amount = error, { min: { value: 1, error: "Too low" }, max: { value: 10000000, error: "Too big" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Error"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

export const loadVTPassBouquets = instance => {

    let data = {
        vendor: "VTPASS"
    }

    Requests(instance.props)
        .post()
        .withUrl("/admin/services/cabletv/vendor/bouquets")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleVTPassBouquetsResponse(instance, response)
        }, error => {
            alert("Connection Failed")
            instance.props.history.push('/services/cabletv/bouquet')
        })
}

const handleVTPassBouquetsResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingVTPassBouquets: false,
                vtpassBouquets: response.data.bouquets
            })
            break
        default:
            alert(response.status.message)
            instance.props.history.push('/services/cabletv/bouquet')
    }
}

export const loadBaxiBouquets = instance => {

    let data = {
        vendor: "BAXI"
    }

    Requests(instance.props)
        .post()
        .withUrl("/admin/services/cabletv/vendor/bouquets")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleBaxiBouquetsResponse(instance, response)
        }, error => {
            alert("Connection Failed")
            instance.props.history.push('/services/cabletv/bouquet')
        })
}

const handleBaxiBouquetsResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingBaxiBouquets: false,
                baxiBouquets: response.data.bouquets
            })
            break
        default:
            alert(response.status.message)
            instance.props.history.push('/services/cabletv/bouquet')
    }
}