import Validation from '../../../data/validation/validation'
import { StringValidationRule } from '../../../data/validation/rules'
import Requests from '../../../utils/Requests'

export const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.area.routeId, (error) => errors.routeId = error, { min: { value: 2, error: "Invalid" }, max: { value: 36, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.area.name, (error) => errors.name = error, { min: { value: 0, error: "Invalid" }, max: { value: 50, error: "Invalid" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Error"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}


export const loadRoutes = instance => {

    instance.setState({ ...instance.state, loadingAreas: true })
    let data = {
        offSet: 1,
        maxSize: 1000
    }

    Requests(instance.props)
        .post()
        .withUrl("/admin/delivery/routes")
        .withAuthorization(instance.props.auth.authorization)
        .withData(data)
        .then(response => {
            handleLoadRouteResponse(instance, response)
        }, error => {
            handleError(instance, "Connection Failed")
        })
}

const handleLoadRouteResponse = (instance, response) => {
    switch (response.status.code) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingRoutes: false,
                routes: response.data.routes,
                total: response.data.size
            })
            break
        default:
            handleError(instance, response.status.message)
    }
}

const handleError = (instance, error) => {
    instance.setState({
        ...instance.state,
        loadRoutes: false,
        creating: false,
        saving: false,
        showError: true,
        alertError: {
            title: "Error",
            message: error,
            action: "Okay",
            onClick: () => instance.setState({
                ...instance.state,
                showError: false
            }),
            onClose: () => instance.setState({
                ...instance.state,
                showError: false
            })
        }
    })
}