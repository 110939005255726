import React from 'react'
import { Switch, Route } from "react-router-dom"
import RouteController from './route/route.controller'
import AreaController from './area/area.controller'
import DeliveryOptionController from './delivery-option/delivery-option.controller'
import PricingController from './pricing/pricing.controller'
import './common.styles.scss'

const DeliveryController = () => {
    return (
        <div className="delivery">
            <Switch>
                <Route path="/delivery/route" component={RouteController} />
                <Route path="/delivery/area" component={AreaController} />
                <Route path="/delivery/delivery-option" component={DeliveryOptionController} />
                <Route path="/delivery/pricing" component={PricingController} />
            </Switch>
        </div>
    )
}

export default DeliveryController