import React from 'react'
import Service from './delete.service'
import Loading from '../../../../../components/loading/loading.component'
import { connect } from 'react-redux'
import AlertDialog from '../../../../../components/dialog/alert/alert.component'

class DeletePricing extends React.Component {
    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.onDelete()
    }

    onDelete = () => {
        this.setState({
            ...this.state,
            deleting: true
        }, () => this.service.deletePricing())
    }

    render() {
        return (
            <div className="delete card">
                {this.state.showError && <AlertDialog {...this.state.alertError} />}
                <div className="header">
                    <h4>Delete Pricing</h4>
                </div>
                {
                    this.state.deleting ?
                        <Loading /> :
                        <form onSubmit={this.onSubmit}>
                            <p>Do you really want to delete <strong>{this.props.pricing.name}</strong>?</p>
                            <div className="actions">
                                <button type="reset" onClick={this.props.onCancel}>Cancel</button>
                                <button type="submit">Delete</button>
                            </div>
                        </form>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}


export default connect(mapStateToProps)(DeletePricing)