import React from 'react'
import { Switch, Route } from "react-router-dom"
import CabletvController from './cabletv/cabletv.controller'
import './common.styles.scss'

const ServicesController = () => {
    return (
        <div className="services">
            <Switch>
                <Route path="/services/cabletv" component={CabletvController} />
            </Switch>
        </div>
    )
}

export default ServicesController